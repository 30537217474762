import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { ConfigurationLabelLMApi } from "src/app/api";

@Pipe({
  name: "translateLabel",
})
export class TranslateLabelPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(value: ConfigurationLabelLMApi[] | null | undefined, ...args: any[]): Observable<string | null | undefined> {
    let obs = new BehaviorSubject<string | null | undefined>(undefined);
    if (value && value.length > 0) {
      this.translate.onLangChange.subscribe((x) => {
        let lang = this.translate.currentLang;
        let res = value.find((x) => x.lang?.toLowerCase() == lang.toLowerCase());
        obs.next(res ? res.label : value.find((x) => x.lang?.toLowerCase() == "en")?.label);
      });
      let lang = this.translate.currentLang;
      let res = value.find((x) => x.lang?.toLowerCase() == lang.toLowerCase());
      obs.next(res ? res.label : value.find((x) => x.lang?.toLowerCase() == "en")?.label);
    } else {
      obs.next(null);
    }

    return obs.asObservable();
  }
}
