import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { ActionTypeLMApi, ConfigurationTypesCodeNameOrderIconLMApi, CustomerPlantConfigurationModelExtLMApi, ProductBookingLMApi } from "src/app/api";
import { IProductData } from "src/app/models/interfaces";

@Component({
  selector: "app-edit-product-dialog",
  template: `
    <div *ngIf="loaded" class="modal-header">
      <h3 class="modal-title">
        {{ "DATA_EDIT_PRODUCT" | translate }}
      </h3>
    </div>
    <div class="modal-body">
      <form #dataForm="ngForm">
        <div class="form-group mb-2" *ngIf="typeActive?.visible">
          <label>
            {{ typeActive?.labelTranslations | translateLabel | async }}
            {{ typeActive?.required ? " *" : "" }}
          </label>
          <ng-select
            [items]="typeActive?.types!"
            class="form-control p-0"
            [(ngModel)]="model.type"
            [ngClass]="{ 'is-invalid border border-danger border-3': type.invalid }"
            [required]="typeActive?.required!"
            [searchable]="false"
            #type="ngModel"
            name="type"
            bindLabel="name"
            autocomplete="off"
          >
            <ng-template ng-option-tmp let-item="item">
              <app-img-base64 [base64string]="item.icon"></app-img-base64>
              {{ item.name }}
            </ng-template>
          </ng-select>
        </div>
        <div class="form-group mb-2" *ngIf="configuration.product?.visible">
          <label>
            {{ configuration.product?.labelTranslations | translateLabel | async }}
            {{ configuration.product?.required ? " *" : "" }}
          </label>
          <ng-select
            [items]="configuration.product?.types!"
            class="form-control p-0"
            [(ngModel)]="model.product"
            [ngClass]="{ 'is-invalid border border-danger border-3': product.invalid }"
            [required]="configuration.product?.required!"
            [searchable]="false"
            #product="ngModel"
            name="product"
            bindLabel="name"
            autocomplete="off"
          >
            <ng-template ng-option-tmp let-item="item">
              <app-img-base64 [base64string]="item.icon"></app-img-base64>
              {{ item.name }}
            </ng-template>
          </ng-select>
        </div>
        <div class="form-group mb-2" *ngIf="configuration.quantity?.visible">
          <label>
            {{ configuration.quantity?.labelTranslations | translateLabel | async }}
            {{ configuration.quantity?.required ? " *" : "" }}
          </label>
          <input
            type="number"
            name="quantity"
            class="form-control"
            [(ngModel)]="model.quantity"
            #quantity="ngModel"
            [ngClass]="{ 'is-invalid border border-danger border-3': quantity.invalid || (model.quantity && (model.quantity <= 0 || model.quantity > quantityLimit())) }"
            [required]="configuration.quantity?.required!"
            autocomplete="off"
            [min]="1"
            [max]="quantityLimit()"
          />
        </div>
        <div class="form-group mb-2" *ngIf="configuration.deliveryMethod?.visible">
          <label>
            {{ configuration.deliveryMethod?.labelTranslations | translateLabel | async }}
            {{ configuration.deliveryMethod?.required ? " *" : "" }}
          </label>
          <ng-select
            [items]="configuration.deliveryMethod?.types!"
            class="form-control p-0"
            [(ngModel)]="model.deliveryMethod"
            [ngClass]="{ 'is-invalid border border-danger border-3': deliveryMethod.invalid }"
            [required]="configuration.deliveryMethod?.required!"
            [searchable]="false"
            #deliveryMethod="ngModel"
            name="deliveryMethod"
            bindLabel="name"
            autocomplete="off"
          >
            <ng-template ng-option-tmp let-item="item">
              <app-img-base64 [base64string]="item.icon"></app-img-base64>
              {{ item.name }}
            </ng-template>
          </ng-select>
        </div>
        <div class="form-group mb-2" *ngIf="configuration.sourceDestination?.visible">
          <label>
            {{ configuration.sourceDestination?.labelTranslations | translateLabel | async }}
            {{ configuration.sourceDestination?.required ? " *" : "" }}
          </label>
          <ng-select
            [items]="configuration.sourceDestination?.types!"
            class="form-control p-0"
            [(ngModel)]="model.sourceDestination"
            [ngClass]="{ 'is-invalid border border-danger border-3': sourceDestination.invalid }"
            [required]="configuration.sourceDestination?.required!"
            [searchable]="false"
            #sourceDestination="ngModel"
            name="sourceDestination"
            bindLabel="name"
            autocomplete="off"
          >
            <ng-template ng-option-tmp let-item="item">
              {{ item.name }}
            </ng-template>
          </ng-select>
        </div>
      </form>
    </div>
    <div class="modal-footer justify-content-center p-0">
      <button type="button" class="btn btn-light btn-footer-mobile left" aria-label="Close" (click)="activeModal.dismiss()">
        {{ "DIALOG_CLOSE" | translate }}
      </button>
      <button type="button" class="btn btn-primary btn-footer-mobile right" (click)="add()">
        {{ "DIALOG_EDIT" | translate }}
      </button>
    </div>
  `,
})
export class EditProductDialogComponent implements OnInit {
  @Input() configuration!: CustomerPlantConfigurationModelExtLMApi;
  @Input() typeActive: ConfigurationTypesCodeNameOrderIconLMApi | undefined = undefined;
  @Input() data!: ProductBookingLMApi;
  constructor(public activeModal: NgbActiveModal) {}
  loaded: boolean = false;
  model: IProductData = {
    id: undefined,
    type: null,
    product: null,
    quantity: undefined,
    deliveryMethod: null,
    sourceDestination: null,
  };

  ngOnInit(): void {
    this.model.id = this.data.id;
    this.model.type = this.typeActive?.types?.find((x) => x.code == this.data.action);
    this.model.product = this.configuration.product?.types?.find((x) => x.code == this.data.code);
    this.model.quantity = this.data.quantity;
    this.model.deliveryMethod = this.configuration.deliveryMethod?.types?.find((x) => x.code == this.data.deliveryMethod);
    this.model.sourceDestination = this.configuration.sourceDestination?.types?.find((x) => x.code == this.data.sourceDestination);

    if (this.typeActive?.types?.length == 1) {
      this.model.type = this.typeActive.types[0];
    }
    if (this.configuration.product?.types?.length == 1) {
      this.model.product = this.configuration.product.types[0];
    }
    if (this.configuration.deliveryMethod?.types?.length == 1) {
      this.model.deliveryMethod = this.configuration.deliveryMethod.types[0];
    }
    if (this.configuration.sourceDestination?.types?.length == 1) {
      this.model.sourceDestination = this.configuration.sourceDestination.types[0];
    }
    this.loaded = true;
  }

  quantityLimit = () => {
    if (this.model.product) {
      let limit = this.configuration.product?.types?.find((x) => x.code === this.model.product?.code)?.limit ?? 9999;
      return limit;
    } else {
      return 9999;
    }
  };

  add = () => {
    let result: ProductBookingLMApi = {
      id: this.model.id,
      action: this.model.type?.code == ActionTypeLMApi.Load ? ActionTypeLMApi.Load : ActionTypeLMApi.Unload,
      code: this.model.product?.code,
      quantity: this.model.quantity,
      deliveryMethod: this.model.deliveryMethod?.code,
      sourceDestination: this.model.sourceDestination?.code,
    };
    this.activeModal.close(result);
  };
}
