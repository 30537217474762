import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomerPlantModelExtLMApi } from "src/app/api";

@Component({
  selector: "app-leaflet-info-dialog",
  template: `
    <div *ngIf="loaded" class="modal-body text-center">
      <h3 class="mb-2">
        {{ data.description }}
      </h3>
      <h6>{{ data.address }}</h6>
      <h6>{{ data.cap }}</h6>
      <h6>{{ data.city }}</h6>
    </div>
    <div class="modal-footer justify-content-center p-0">
      <button type="button" class="btn btn-light btn-footer-mobile left" aria-label="Close" (click)="activeModal.dismiss()">
        {{ "DIALOG_CLOSE" | translate }}
      </button>
      <button type="button" class="btn btn-primary btn-footer-mobile right" (click)="select(data)">
        {{ "DESTINATION_SELECT" | translate }}
      </button>
    </div>
  `,
})
export class LeafletInfoDialogComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}
  loaded: boolean = false;
  @Input() data!: CustomerPlantModelExtLMApi;
  ngOnInit(): void {
    this.loaded = true;
  }

  select = (data: CustomerPlantModelExtLMApi) => {
    this.activeModal.close(data);
  };
}
