import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, fromEvent, throttleTime } from "rxjs";
import {
  BookingShipmentLMApi,
  CanEditBookingResponseLMApi,
  CodeNameOrderLMApi,
  CustomerPlantConfigurationModelExtLMApi,
  CustomerPlantModelExtLMApi,
  GetAuthTokenFromBookingCodeResponseLMApi,
  GetSlotsResponseLMApi,
  ShipmentInfoLMApi,
  SlotLMApi,
} from "../api";
import { AttachmentFileData, AttachmentFileTempData, IBookStep, IBookStepper } from "../models/interfaces";
import { PreBookingInformation } from "../dialogs/add-prebooking-dialog/add-prebooking-dialog.component";
const emptyProduct = {
  id: 0,
  action: undefined,
  code: null,
  quantity: undefined,
  deliveryMethod: null,
  sourceDestination: null,
};
@Injectable({
  providedIn: "root",
})
export class BookingService {
  DEFAULT_DATA: BookingShipmentLMApi = { products: [{ ...emptyProduct }] };
  /* LANG */
  private _lang = new BehaviorSubject<string | null>("en");
  public get lang() {
    return this._lang.value;
  }
  public set lang(value: string | null) {
    this._lang.next(value);
  }
  public $lang: Observable<string | null> = this._lang.asObservable();

  /* WAREHOUSES LIST */
  private _warehousesList = new BehaviorSubject<CustomerPlantModelExtLMApi[] | null>(null);
  public get warehousesList() {
    return this._warehousesList.value;
  }
  public set warehousesList(value: CustomerPlantModelExtLMApi[] | null) {
    this._warehousesList.next(value);
  }
  $warehousesList: Observable<CustomerPlantModelExtLMApi[] | null> = this._warehousesList.asObservable();

  /* WAREHOUSE CONFIGURATION */
  private _warehouseConfiguration = new BehaviorSubject<CustomerPlantConfigurationModelExtLMApi>({});
  public get warehouseConfiguration() {
    return this._warehouseConfiguration.value;
  }
  public set warehouseConfiguration(value: CustomerPlantConfigurationModelExtLMApi) {
    this._warehouseConfiguration.next(value);
  }
  $warehouseConfiguration: Observable<CustomerPlantConfigurationModelExtLMApi> = this._warehouseConfiguration.asObservable();

  /* SELECTED WAREHOUSE DATA */
  private _selectedWarehouseData = new BehaviorSubject<CustomerPlantModelExtLMApi | undefined>(undefined);
  public get selectedWarehouseData() {
    return this._selectedWarehouseData.value;
  }
  public set selectedWarehouseData(value: CustomerPlantModelExtLMApi | undefined) {
    this._selectedWarehouseData.next(value);
  }
  $selectedWarehouseData: Observable<CustomerPlantModelExtLMApi | undefined> = this._selectedWarehouseData.asObservable();

  /* ATTACHMENT RANDOM GUID FOR TEMPORARY FILES */
  private _attachmentRandomGuidForTemporary = new BehaviorSubject<string | null | undefined>(undefined);
  public get attachmentRandomGuidForTemporary() {
    return this._attachmentRandomGuidForTemporary.value;
  }
  public set attachmentRandomGuidForTemporary(value: string | null | undefined) {
    this._attachmentRandomGuidForTemporary.next(value);
  }
  $attachmentRandomGuidForTemporary: Observable<string | null | undefined> = this._attachmentRandomGuidForTemporary.asObservable();

  /* SHIPMENT DATA */
  private _data = new BehaviorSubject<BookingShipmentLMApi>({});
  public get data() {
    return this._data.value;
  }
  public set data(value: BookingShipmentLMApi) {
    this._data.next(value);
  }
  $data: Observable<BookingShipmentLMApi> = this._data.asObservable();

  /* FILE TEMP PATH */
  private _bookingFileTempPath = new BehaviorSubject<string | null | undefined>(undefined);
  public get bookingFileTempPath() {
    return this._bookingFileTempPath.value;
  }
  public set bookingFileTempPath(value: string | null | undefined) {
    this._bookingFileTempPath.next(value);
  }
  $bookingFileTempPath: Observable<string | null | undefined> = this._bookingFileTempPath.asObservable();

  /* FILE TEMPORARY LIST */
  private _attachmentsFileTempList = new BehaviorSubject<AttachmentFileTempData[][] | null | undefined>([]);
  public get attachmentsFileTempList() {
    return this._attachmentsFileTempList.value;
  }
  public set attachmentsFileTempList(value: AttachmentFileTempData[][] | null | undefined) {
    this._attachmentsFileTempList.next(value);
  }
  $attachmentsFileTempList: Observable<AttachmentFileTempData[][] | null | undefined> = this._attachmentsFileTempList.asObservable();

  /* FILE LIST */
  private _attachmentsFileList = new BehaviorSubject<AttachmentFileData[][] | null | undefined>([]);
  public get attachmentsFileList() {
    return this._attachmentsFileList.value;
  }
  public set attachmentsFileList(value: AttachmentFileData[][] | null | undefined) {
    this._attachmentsFileList.next(value);
  }
  $attachmentsFileList: Observable<AttachmentFileData[][] | null | undefined> = this._attachmentsFileList.asObservable();

  /* CALCULATED MIN VALID DATE */
  private _minValidDate = new BehaviorSubject<Date | undefined>(undefined);
  public get minValidDate() {
    return this._minValidDate.value;
  }
  public set minValidDate(value: Date | undefined) {
    this._minValidDate.next(value);
  }
  $minValidDate: Observable<Date | undefined> = this._minValidDate.asObservable();

  /* CALCULATED MAX VALID DATE */
  private _maxValidDate = new BehaviorSubject<Date | undefined>(undefined);
  public get maxValidDate() {
    return this._maxValidDate.value;
  }
  public set maxValidDate(value: Date | undefined) {
    this._maxValidDate.next(value);
  }
  $maxValidDate: Observable<Date | undefined> = this._maxValidDate.asObservable();

  /* SELECTED DATE */
  private _selectedDate = new BehaviorSubject<Date | undefined>(undefined);
  public get selectedDate() {
    return this._selectedDate.value;
  }
  public set selectedDate(value: Date | undefined) {
    this._selectedDate.next(value);
  }
  $selectedDate: Observable<Date | undefined> = this._selectedDate.asObservable();

  /* SELECTED HOUR */
  private _selectedHour = new BehaviorSubject<number | undefined>(undefined);
  public get selectedHour() {
    return this._selectedHour.value;
  }
  public set selectedHour(value: number | undefined) {
    this._selectedHour.next(value);
  }
  $selectedHour: Observable<number | undefined> = this._selectedHour.asObservable();

  /* MIN DATE PREBOOKING */
  private _prebookingList = new BehaviorSubject<PreBookingInformation[] | undefined>(undefined);
  public get prebookingList() {
    return this._prebookingList.value;
  }
  public set prebookingList(value: PreBookingInformation[] | undefined) {
    this._prebookingList.next(value);
  }
  $prebookingList: Observable<PreBookingInformation[] | undefined> = this._prebookingList.asObservable();

  /* MIN DATE PREBOOKING */
  private _prebookingMinDate = new BehaviorSubject<Date | undefined>(undefined);
  public get prebookingMinDate() {
    return this._prebookingMinDate.value;
  }
  public set prebookingMinDate(value: Date | undefined) {
    this._prebookingMinDate.next(value);
  }
  $prebookingMinDate: Observable<Date | undefined> = this._prebookingMinDate.asObservable();

  /* MAX DATE PREBOOKING */
  private _prebookingMaxDate = new BehaviorSubject<Date | undefined>(undefined);
  public get prebookingMaxDate() {
    return this._prebookingMaxDate.value;
  }
  public set prebookingMaxDate(value: Date | undefined) {
    this._prebookingMaxDate.next(value);
  }
  $prebookingMaxDate: Observable<Date | undefined> = this._prebookingMaxDate.asObservable();

  /* PREBOOKING EXCLUSIVE */
  private _prebookingExclusive = new BehaviorSubject<boolean | null | undefined>(undefined);
  public get prebookingExclusive() {
    return this._prebookingExclusive.value;
  }
  public set prebookingExclusive(value: boolean | null | undefined) {
    this._prebookingExclusive.next(value);
  }
  $prebookingExclusive: Observable<boolean | null | undefined> = this._prebookingExclusive.asObservable();

  /* AVAILABLE SLOTS */
  private _getSlots = new BehaviorSubject<GetSlotsResponseLMApi | undefined>(undefined);
  public get getSlots() {
    return this._getSlots.value;
  }
  public set getSlots(value: GetSlotsResponseLMApi | undefined) {
    this._getSlots.next(value);
  }
  $getSlots: Observable<GetSlotsResponseLMApi | undefined> = this._getSlots.asObservable();

  /* SELECTED SLOT */
  private _selectedSlot = new BehaviorSubject<SlotLMApi | null | undefined>(undefined);
  public get selectedSlot() {
    return this._selectedSlot.value;
  }
  public set selectedSlot(value: SlotLMApi | null | undefined) {
    this._selectedSlot.next(value);
  }
  $selectedSlot: Observable<SlotLMApi | null | undefined> = this._selectedSlot.asObservable();

  /* TOKEN FOR EDIT */
  private _token = new BehaviorSubject<GetAuthTokenFromBookingCodeResponseLMApi | null | undefined>(undefined);
  public get token() {
    return this._token.value;
  }
  public set token(value: GetAuthTokenFromBookingCodeResponseLMApi | null | undefined) {
    this._token.next(value);
  }
  $token: Observable<GetAuthTokenFromBookingCodeResponseLMApi | null | undefined> = this._token.asObservable();

  /* VIEW DATA */
  private _viewData = new BehaviorSubject<ShipmentInfoLMApi | null | undefined>(undefined);
  public get viewData() {
    return this._viewData.value;
  }
  public set viewData(value: ShipmentInfoLMApi | null | undefined) {
    this._viewData.next(value);
  }
  $viewData: Observable<ShipmentInfoLMApi | null | undefined> = this._viewData.asObservable();

  /* TICKET PDF */
  private _ticketPDF = new BehaviorSubject<Blob | null>(null);
  public get ticketPDF() {
    return this._ticketPDF.value;
  }
  public set ticketPDF(value: Blob | null) {
    this._ticketPDF.next(value);
  }
  $ticketPDF: Observable<Blob | null> = this._ticketPDF.asObservable();

  /* EDIT REASON*/
  private _reason = new BehaviorSubject<string | undefined>(undefined);
  public get reason() {
    return this._reason.value;
  }
  public set reason(value: string | undefined) {
    this._reason.next(value);
  }
  $reason: Observable<string | undefined> = this._reason.asObservable();

  /* EDIT REASON TYPE*/
  private _reasonTypeCode = new BehaviorSubject<string | null | undefined>(undefined);
  public get reasonTypeCode() {
    return this._reasonTypeCode.value;
  }
  public set reasonTypeCode(value: string | null | undefined) {
    this._reasonTypeCode.next(value);
  }
  $reasonTypeCode: Observable<string | null | undefined> = this._reasonTypeCode.asObservable();

  /* STEPPER */
  private _stepper = new BehaviorSubject<IBookStepper>({ steps: [], active: 0 });
  $stepper: Observable<IBookStepper> = this._stepper.asObservable();

  canEditResponse: CanEditBookingResponseLMApi | undefined;
  checkSameSlotValid: boolean | undefined;
  canSelectNewSlot: boolean | undefined;

  editInitialBookingDate: string | undefined;
  desktopStep: number | undefined = 0;
  isInjected: boolean | undefined;
  myBookingsWho: string | null | undefined;
  fromByPlant: boolean | undefined;
  fromPrebooking: boolean | undefined;
  forcePrebooking: number | undefined;
  plantCryptedId: string | null | undefined;

  preBookingInformationList = new BehaviorSubject<PreBookingInformation[] | undefined>(undefined);

  isScreenSmall$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    // EVENTO PER GESTIRE IL RESIZE
    fromEvent(window, "resize")
      .pipe(throttleTime(100))
      .subscribe((_) => {
        this.isScreenSmall$.next(document.body.clientWidth < 1024);
      });
    // INIZIALIZZAZIONE IN BASE ALLA DIMENSIONE INIZIALE DELLO SCHERMO
    this.isScreenSmall$.next(document.body.clientWidth < 1024);
  }

  checkConfiguration = (configuration: CustomerPlantConfigurationModelExtLMApi) => {
    let data = this._data.value;
    if (configuration.truckType?.types?.length == 1) {
      data.truckType = configuration.truckType.types[0].code;
    }
  };

  setStepper = (steps: IBookStep[]) => {
    this._stepper.next({ steps: steps, active: this._stepper.value.active });
  };
  setStepperActive = (active: number, disabled?: boolean) => {
    this._stepper.next({ steps: this._stepper.value.steps, active: active, disabled: disabled });
  };

  resetBooking = () => {
    this.resetModel();
    this._stepper.value.steps.length = 0;
    this._stepper.next({ steps: [], active: 0 });
    this.warehousesList = null;
    this.warehouseConfiguration = {};
    this.selectedWarehouseData = undefined;
    this.isInjected = undefined;
    this.myBookingsWho = undefined;
    this.fromByPlant = undefined;
    this.fromPrebooking = undefined;
    this.forcePrebooking = undefined;
    this.plantCryptedId = undefined;
    this.preBookingInformationList.next(undefined);
  };
  resetModel = () => {
    this.data = {};
    this.reason = undefined;
    this.reasonTypeCode = undefined;
    this.desktopStep = 0;
    this.canEditResponse = undefined;
    this.editInitialBookingDate = undefined;
    this.prebookingList = undefined;
    this.prebookingExclusive = undefined;
    this.prebookingMaxDate = undefined;
    this.prebookingMinDate = undefined;
    this.selectedDate = undefined;
    this.selectedHour = undefined;
    this.minValidDate = undefined;
    this.attachmentRandomGuidForTemporary = undefined;
    if (this.attachmentsFileList) {
      this.attachmentsFileList.length = 0;
      this.attachmentsFileList = [[], [], []];
    }
    if (this.attachmentsFileTempList) {
      this.attachmentsFileTempList.length = 0;
      this.attachmentsFileTempList = [[], [], []];
    }
    this.bookingFileTempPath = undefined;
    this.getSlots = undefined;

    this.canEditResponse = undefined;
    this.checkSameSlotValid = undefined;
    this.canSelectNewSlot = undefined;
  };
}
