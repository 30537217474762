import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-deleted",
  templateUrl: "deleted.component.html",
})
export class DeletedComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
