import { Component, ViewChild } from "@angular/core";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { LANGUAGES } from "src/app/models/languages";
import { BookingService } from "src/app/services/booking.service";
import { TopBarService } from "src/app/services/topbar.service";

@Component({
  selector: "app-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.scss"],
})
export class NavMenuComponent {
  @ViewChild("langMenu", { static: false }) langMenu!: NgbDropdown;

  url: string = environment.baseAppUrl;
  languages = LANGUAGES;
  search: string = "";
  myBookingUrl: string = "";
  hamburgerColor: string = "#ffffff";

  constructor(private router: Router, private bookingService: BookingService, public topBarService: TopBarService) {
    //this.url = sessionStorage['base_url'] ?? environment.baseAppUrl;
    this.myBookingUrl = window.location.origin + "/my-bookings";

    this.topBarService.color.subscribe((x) => {
      if (x) {
        this.hamburgerColor = this.checkHeaderColor(x);
      } else {
        this.hamburgerColor = "#ffffff";
      }
    });
  }
  checkHeaderColor = (color: string) => {
    color = color.slice(1);
    var r = parseInt(color.slice(0, 2), 16),
      g = parseInt(color.slice(2, 4), 16),
      b = parseInt(color.slice(4, 6), 16);
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
  };

  language($event: MouseEvent) {
    $event.preventDefault();
    this.langMenu.open();
  }
  edit() {
    if (!this.search || !/^\D+\d{10}$/.test(this.search)) return;
    this.myBookingUrl = window.location.origin + "/edit/" + this.search.trim().toUpperCase();
    location.href = this.myBookingUrl;
  }
  setLang(lang: string) {
    document.cookie = `.User.Locale=${lang};path=/;domain=${environment.cookiedomain}`;
    this.bookingService.lang = lang;
    localStorage.setItem("lang", lang);
  }
  goToLMHome() {
    location.href = sessionStorage['base_url'] ?? environment.baseAppUrl;
  }
}
