import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-404',
  templateUrl: '404.component.html',
})
export class Page404Component implements OnInit {
  constructor() {}

  ngOnInit() {}
}
