import { ILanguage } from './interfaces';

export const LANGUAGES: ILanguage[] = [
  { file: 'ger.webp', id: 'de', name: 'Deutsch' },
  { file: 'eng.webp', id: 'en', name: 'English' },
  { file: 'esp.webp', id: 'es', name: 'Español' },
  { file: 'fra.webp', id: 'fr', name: 'Français' },
  { file: 'ita.webp', id: 'it', name: 'Italiano' },
  { file: 'pol.webp', id: 'pl', name: 'Polski' },
  { file: 'rom.webp', id: 'ro', name: 'Română' },
  { file: 'rus.webp', id: 'ru', name: 'Русский' },
];
