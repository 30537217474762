import { Component, EventEmitter, Input, OnChanges, Output, TemplateRef } from "@angular/core";
import { ProductBookingLMApi, ActionTypeLMApi, ConfigurationTypesCodeNameOrderIconLMApi, CustomerPlantConfigurationModelExtLMApi } from "src/app/api";
import { IProductData } from "src/app/models/interfaces";

@Component({
  selector: "[app-add-product]",
  templateUrl: "./add-product.component.html",
})
export class AddProductComponent implements OnChanges {
  @Input() configuration!: CustomerPlantConfigurationModelExtLMApi;
  @Input() typeActive: ConfigurationTypesCodeNameOrderIconLMApi | undefined = undefined;
  @Input() product!: ProductBookingLMApi;
  @Input() canEdit: boolean | undefined;
  @Output() removeProduct = new EventEmitter<void>();
  @Output() updateProduct = new EventEmitter<void>();
  constructor() {}
  loaded: boolean = false;
  model: IProductData = {
    id: 0,
    type: null,
    product: null,
    quantity: undefined,
    deliveryMethod: null,
    sourceDestination: null,
  };

  ngOnChanges(): void {
    if (this.typeActive?.types?.length == 1) {
      this.model.type = this.typeActive.types[0];
      this.product.action = this.model.type?.code == ActionTypeLMApi.Load ? ActionTypeLMApi.Load : ActionTypeLMApi.Unload;
    }
    if (this.configuration.product?.types?.length == 1) {
      this.model.product = this.configuration.product.types[0];
      this.product.code = this.model.product?.code;
    }
    if (this.configuration.deliveryMethod?.types?.length == 1) {
      this.model.deliveryMethod = this.configuration.deliveryMethod.types[0];
      this.product.deliveryMethod = this.model.deliveryMethod?.code;
    }
    if (this.configuration.sourceDestination?.types?.length == 1) {
      this.model.sourceDestination = this.configuration.sourceDestination.types[0];
      this.product.sourceDestination = this.model.sourceDestination?.code;
    }

    this.model.id = this.product.id;
    this.model.type = this.typeActive?.types?.find((x) => x.code == this.product.action);
    this.model.product = this.configuration.product?.types?.find((x) => x.code == this.product.code);
    this.model.quantity = this.product.quantity;
    this.model.deliveryMethod = this.configuration.deliveryMethod?.types?.find((x) => x.code == this.product.deliveryMethod);
    this.model.sourceDestination = this.configuration.sourceDestination?.types?.find((x) => x.code == this.product.sourceDestination);
    this.loaded = true;
  }
  quantityLimit = () => {
    if (this.model.product) {
      let limit = this.configuration.product?.types?.find((x) => x.code === this.model.product?.code)?.limit ?? Infinity;
      return limit;
    } else {
      return Infinity;
    }
  };

  removeRow = () => {
    this.removeProduct.next();
  };

  update = () => {
    this.product.id = this.model.id;
    this.product.action =
      this.model.type?.code == ActionTypeLMApi.Load ? ActionTypeLMApi.Load : this.model.type?.code == ActionTypeLMApi.Unload ? ActionTypeLMApi.Unload : undefined;
    this.product.code = this.model.product?.code;
    this.product.quantity = this.model.quantity;
    this.product.deliveryMethod = this.model.deliveryMethod?.code;
    this.product.sourceDestination = this.model.sourceDestination?.code;
    this.updateProduct.emit();
  };
}
