import { AfterViewInit, Component, ViewChild } from "@angular/core";
import { NgbActiveModal, NgbDateStruct, NgbDatepicker } from "@ng-bootstrap/ng-bootstrap";
import { BookingService } from "src/app/services/booking.service";
import { getDateInTimezone } from "src/app/services/utils";

@Component({
  selector: "app-slot-date-selection-dialog",
  template: `
    <!-- <div class="modal-header">
      <h3 class="modal-title">
        {{ "SLOT_DATE_SELECTION_DIALOG_TITLE" | translate }}
      </h3>
    </div>
    <div class="modal-body"> -->
    <ngb-datepicker class="w-100" #dp [(ngModel)]="dateStruct" [minDate]="minDateStruct" [footerTemplate]="t" />
    <ng-template #t>
      <div class="w-100 d-flex flex-row justify-content-between">
        <button type="button" class="btn btn-secondary btn-50-mobile" aria-label="Close" (click)="activeModal.dismiss()">
          {{ "DIALOG_CLOSE" | translate }}
        </button>
        <button type="button" class="btn btn-primary btn-50-mobile" style="color: white;" aria-label="Close" (click)="save()">
          {{ "DIALOG_PROCEED" | translate }}
        </button>
      </div>
    </ng-template>
    <!--</div>
      <div class="modal-footer">
      <button type="button" class="btn btn-primary btn-50-mobile" aria-label="Close" (click)="activeModal.dismiss()">
        {{ "DIALOG_CLOSE" | translate }}
      </button>
      <button type="button" class="btn btn-primary btn-50-mobile" style="color: white;" aria-label="Close" [disabled]="!selectedDate" (click)="activeModal.close(selectedDate)">
        {{ "DIALOG_PROCEED" | translate }}
      </button>
    </div> -->
  `,
})
export class SlotDateSelectionDialogComponent implements AfterViewInit {
  dateStruct: NgbDateStruct | undefined;
  minDateStruct!: NgbDateStruct;
  selectedDate: Date | undefined;
  @ViewChild("dp") datePicker!: NgbDatepicker;

  constructor(public activeModal: NgbActiveModal, private bookingService: BookingService) {
  }
  ngAfterViewInit(): void {
    if (this.bookingService.selectedDate) {
      this.dateStruct = {
        year: this.bookingService.selectedDate.getFullYear(),
        month: this.bookingService.selectedDate.getMonth() + 1,
        day: this.bookingService.selectedDate.getDate(),
      };
      this.datePicker.navigateTo(this.dateStruct);
    }
    this.minDateStruct = {
      year: this.bookingService.minValidDate!.getFullYear(),
      month: this.bookingService.minValidDate!.getMonth() + 1,
      day: this.bookingService.minValidDate!.getDate(),
    };
  }
  save = () => {
    if (this.dateStruct) {
      let newD = new Date(Date.UTC(this.dateStruct.year, this.dateStruct?.month - 1, this.dateStruct?.day, 0, 0, 0, 0));
      this.selectedDate = getDateInTimezone(newD, this.bookingService.warehouseConfiguration?.timezone ? this.bookingService.warehouseConfiguration?.timezone : "UTC");
      this.selectedDate = this.bookingService.minValidDate
        ? this.selectedDate > this.bookingService.minValidDate
          ? this.selectedDate
          : this.bookingService.minValidDate
        : this.selectedDate;
    }
    this.activeModal.close(this.selectedDate);
  };
}
