import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BookingService } from './services/booking.service';
import { TopBarService } from './services/topbar.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'load-manager-booking-per-android';
  constructor(public translate: TranslateService, private bookingService: BookingService, public topBarService: TopBarService, private recaptchaV3Service: ReCaptchaV3Service) {
    translate.addLangs(['en', 'it', 'ru', 'de', 'fr', 'pl', 'ro']);
    translate.setDefaultLang('en');
    this.bookingService.$lang.subscribe((x) => x && this.translate.use(x));
  }
}
