import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class TopBarService {
  icon = new BehaviorSubject<string | undefined>(undefined);
  color = new BehaviorSubject<string | undefined>(undefined);
  byPage = new BehaviorSubject<boolean>(false);
  navbarShow$ = new BehaviorSubject<boolean>(true);

  constructor() {}

  resetTopBar() {
    this.icon.next(undefined);
    this.color.next(undefined);
    this.byPage.next(false);
  }
  hideNavBar() {
    this.navbarShow$.next(false);
  }
}
