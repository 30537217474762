import { Component, OnDestroy } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { LANGUAGES } from "src/app/models/languages";
import { BookingService } from "src/app/services/booking.service";

@Component({
  selector: "app-privacy-initial-dialog",
  template: `
    <div class="modal-header">
      <h3 class="modal-title">
        {{ "PRIVACY_INITIAL_TITLE" | translate }}
      </h3>
      <div ngbDropdown class="d-flex flags-button">
        <button type="button" class="btn btn-outline-light" ngbDropdownToggle>
          <img [src]="'assets/flags/' + selectedLang.value.file" height="20" width="30" class="object-fit-cover border" />
        </button>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
          <button ngbDropdownItem *ngFor="let l of languages" (click)="selectedLang.setValue(l)">
            <img [src]="'assets/flags/' + l.file" height="20" width="30" class="me-1 object-fit-cover border" />
            {{ l.name }}
          </button>
        </div>
      </div>
    </div>
    <div class="modal-body">
      <div>
        {{ "PRIVACY_INITIAL_MESSAGE1" | translate }}
      </div>
      <a [href]="gdprLink" target="_blank" translate="" class="ng-scope">Open link</a>
      <hr class="mt-3 mb-3" />
      <div>
        {{ "PRIVACY_INITIAL_MESSAGE2" | translate }}
      </div>
      <a [href]="cookieLink" target="_blank" translate="" class="ng-scope">Open link</a>
      <hr class="mt-3 mb-3" />
      <div class="form-check d-flex justify-content-end">
        <input class="form-check-input me-2" style="min-height: 16px" type="checkbox" id="flexCheckDefault" [(ngModel)]="checkBox" />
        <label class="form-check-label" for="flexCheckDefault"> {{ "PRIVACY_INITIAL_CHECK_MESSAGE" | translate }} </label>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary btn-50-mobile" style="color: white;" aria-label="Close" [disabled]="!checkBox" (click)="activeModal.close(true)">
        {{ "DIALOG_PROCEED" | translate }}
      </button>
    </div>
  `,
})
export class PrivacyInitialDialogComponent implements OnDestroy {
  checkBox: boolean = false;
  languages = LANGUAGES;
  selectedLang: FormControl = new FormControl();
  gdprLink: string = "https://loadmanager.cloud/informativa-gdpr-en";
  cookieLink: string = "https://loadmanager.cloud/informativa-cookie-policy-en";

  constructor(public activeModal: NgbActiveModal, private bookingService: BookingService) {
    this.selectedLang.setValue(this.languages.find((y) => y.id == this.bookingService.lang));
    if (this.bookingService.lang === "it") {
      this.gdprLink = "https://loadmanager.cloud/informativa-gdpr";
      this.cookieLink = "https://loadmanager.cloud/informativa-cookie-policy";
    } else {
      this.gdprLink = "https://loadmanager.cloud/informativa-gdpr-en";
      this.cookieLink = "https://loadmanager.cloud/informativa-cookie-policy-en";
    }
    this.selectedLang.valueChanges.subscribe((x) => {
      this.bookingService.lang = this.selectedLang.value.id;
      localStorage.setItem("lang", this.selectedLang.value.id);
      if (this.selectedLang.value.id === "it") {
        this.gdprLink = "https://loadmanager.cloud/informativa-gdpr";
        this.cookieLink = "https://loadmanager.cloud/informativa-cookie-policy";
      } else {
        this.gdprLink = "https://loadmanager.cloud/informativa-gdpr-en";
        this.cookieLink = "https://loadmanager.cloud/informativa-cookie-policy-en";
      }
    });
  }

  ngOnDestroy() {
    if (this.checkBox) {
      localStorage.setItem("load_manager_privacy_version", "2");
    }
  }
}
