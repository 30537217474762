import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BookingLMApiService,
  BookingShipmentLMApi,
  CodeNameOrderIconLMApi,
  CodeNameOrderLMApi,
  GetInfoInputLMApi,
  NewBookingResponseLMApi,
  ShipmentInfoLMApi,
  ShipmentLMApiService,
  EditBookingRequestLMApi,
  EditBookingResponseLMApi,
} from 'src/app/api';
import { BookingService } from 'src/app/services/booking.service';
import { mapForPostMessageShipmentModel } from 'src/app/services/utils';

@Component({
  selector: 'app-edit-add-reason',
  templateUrl: 'edit-add-reason.component.html',
})
export class EditAddReasonComponent {
  error: string | undefined;
  loaded: boolean = false;
  editReasonSelectTypes: CodeNameOrderLMApi[] | null | undefined = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public bookingService: BookingService,
    private apiService: BookingLMApiService,
    private shipmentApiService: ShipmentLMApiService
  ) {
    if (!this.bookingService.data || !this.bookingService.editInitialBookingDate) {
      this.router.navigate(['.'], { relativeTo: this.route.parent });
    }
    this.bookingService.setStepperActive(4);
    this.editReasonSelectTypes = this.bookingService.warehouseConfiguration.reasonTypes;
  }

  goBack = () => {
    this.router.navigate(['.'], { relativeTo: this.route.parent });
  };
  alreadyClicked = false;
  goNext = (event: Event) => {
    this.alreadyClicked = true;
    const target = <HTMLButtonElement>event.currentTarget;
    const elem: Element = document.createElement('div');
    elem.className = 'spinner-border m-auto';
    const temp = target.replaceChildren(elem);
    this.error = undefined;
    let data = this.bookingService.data;
    let bookingShipment: BookingShipmentLMApi = {
      ...data,
      carrier: data.carrier?.toUpperCase(),
      notes: data.notes?.toUpperCase(),
      referenceName: data.referenceName?.toUpperCase(),
      referenceNumber: data.referenceNumber?.toUpperCase(),
      secReferenceNumber: data.secReferenceNumber?.toUpperCase(),
      sender: data.sender?.toUpperCase(),
      receiver: data.receiver?.toUpperCase(),
      truckPlate: data.truckPlate?.toUpperCase(),
      trailerPlate: data.trailerPlate?.toUpperCase(),
      cdn: data.referenceNumber?.toUpperCase(),
      external: data.secReferenceNumber?.toUpperCase(),
      startDT: this.bookingService.selectedSlot?.from!,
      endDT: this.bookingService.selectedSlot?.to!,
      gateCode: this.bookingService.selectedSlot?.code,
      filePath: this.bookingService.bookingFileTempPath,
    };
    let updateShipmentRequest: EditBookingRequestLMApi = {
      shipment: bookingShipment,
      reason: this.bookingService.reason,
      reasonTypeCode: this.bookingService.reasonTypeCode,
      who: this.bookingService.myBookingsWho,
    };

    this.apiService.editBooking(this.bookingService.selectedWarehouseData?.token!, updateShipmentRequest).subscribe((x: EditBookingResponseLMApi) => {
      if (x && x.shipmentCodeCrypted) {
        if (window.location !== window.parent.location) {
          // The page is an iframe
          //API PER GETINFO e poi mandare POSTMESSAGE e andare su view
          this.shipmentApiService.getInfo(this.bookingService.token!.key!, { bookingCode: x.shipmentCodeCrypted } as GetInfoInputLMApi).subscribe((info: ShipmentInfoLMApi) => {
            if (info) {
              window.parent.postMessage(JSON.stringify(mapForPostMessageShipmentModel(info, this.bookingService.selectedWarehouseData!)), '*');
              location.href = window.location.origin + '/view/' + x.shipmentCodeCrypted + '/c';
            }
          });
        } else {
          location.href = window.location.origin + '/view/' + x.shipmentCodeCrypted + '/c';
        }
      } else if (x && x.error) {
        this.error = x.error;
      }
    });
  };
}
