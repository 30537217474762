import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, catchError, filter, forkJoin, of, switchMap } from "rxjs";
import { CustomerPlantModelExtLMApi, ShipmentAttachmentLMApi } from "src/app/api";
import {
  ActionTypeLMApi,
  BookingLMApiService,
  CodeNameOrderIconLMApi,
  ConfigurationTypesCodeNameOrderIconLMApi,
  CustomerPlantConfigurationModelExtLMApi,
  GetAuthTokenFromBookingCodeResponseLMApi,
  GetInfoInputLMApi,
  MandatoryFieldsLMApi,
  ShipmentInfoLMApi,
  ShipmentLMApiService,
} from "src/app/api";
import { BookingService } from "src/app/services/booking.service";
import { TopBarService } from "src/app/services/topbar.service";
import { mobileAndTabletCheck } from "src/app/services/utils";
import { environment } from "src/environments/environment";

declare function downloadFileCSharp(url: string, token: string, fileName: string): any;
declare function goToMyBookings(): any;

@Component({
  selector: "app-view",
  templateUrl: "./view.component.html",
})
export class ViewComponent implements OnInit, OnDestroy {
  bookingCodeCripted: string | null | undefined;
  warehouseAddress: CustomerPlantModelExtLMApi | null | undefined;
  configuration: CustomerPlantConfigurationModelExtLMApi | null | undefined;
  mandatoryFieldsActive: MandatoryFieldsLMApi | undefined;
  typeActive: ConfigurationTypesCodeNameOrderIconLMApi | undefined;
  trackingCode: string | null | undefined;
  shipmentInfo: ShipmentInfoLMApi | null | undefined;
  blob: Blob | null = null;
  now: Date | null = new Date();
  loaded: boolean = false;
  qrCode: string | null = null;
  ticketString: string | null | undefined;
  stepper: boolean = false;
  subToken: Subscription | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public bookingService: BookingService,
    private shipmentApiService: ShipmentLMApiService,
    private bookingApiService: BookingLMApiService,
    private topBarService: TopBarService
  ) {}
  ngOnInit() {
    this.route.paramMap.subscribe((paramMap) => {
      let lang = paramMap.get("lang")!;
      //GESTIONE LINGUA
      let localStorageLang = localStorage.getItem("lang");
      if (localStorageLang) this.bookingService.lang = localStorageLang;
      else if (lang) this.bookingService.lang = lang;

      // SE ARRIVO DA UN FLUSSO DI NEW O EDIT MOSTRO LO STEPPER
      let complete = paramMap.get("complete");
      if (complete) {
        this.stepper = true;
        if (this.bookingService.fromByPlant && !this.bookingService.fromPrebooking) {
          this.bookingService.setStepperActive(3, true);
        } else {
          this.bookingService.setStepperActive(4, true);
        }
      }

      let bookingCodeCripted = paramMap.get("bookingCodeCripted");
      if (bookingCodeCripted) {
        this.bookingCodeCripted = bookingCodeCripted;
        this.shipmentApiService.getAuthTokenFromBookingCode(bookingCodeCripted).subscribe((x: GetAuthTokenFromBookingCodeResponseLMApi) => {
          this.bookingService.token = x;
        });
      }
    });

    this.subToken = this.bookingService.$token
      .pipe(
        filter((token) => token != null && token != undefined),
        switchMap((token: GetAuthTokenFromBookingCodeResponseLMApi | null | undefined) =>
          forkJoin([this.shipmentApiService.getInfo(token!.key!, { bookingCode: this.bookingCodeCripted } as GetInfoInputLMApi)])
        )
      )
      .subscribe(([shipmentInfo]: [ShipmentInfoLMApi | null]) => {
        if (shipmentInfo == null) {
          this.router.navigate(["/404-shipment"]);
        } else {
          this.shipmentInfo = shipmentInfo;
          this.shipmentApiService.getHTMLShipmentCustomTicket(shipmentInfo.bookingCode!, this.bookingService.token!.key!).subscribe((ticketString) => {
            this.ticketString = ticketString;

            //SE ARRIVO DA UN FLUSSO DI NEW O EDIT SCARICO AUTOMATICAMENTE IL TICKET
            if (this.stepper) {
              this.downloadTicket();
            }

            this.loaded = true;
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.subToken?.unsubscribe();
  }

  downloadAttachment = (attachment: ShipmentAttachmentLMApi) => {
    let customerDatabaseId = this.shipmentInfo?.bookingCode?.slice(0, -10) as string;
    if (mobileAndTabletCheck()) {
      downloadFileCSharp(
        `${environment.baseApiUrl}/api/Shipment/DownloadShipmentAttachment/${customerDatabaseId}/${this.shipmentInfo?.warehouseCode!}/${this.shipmentInfo
          ?.bookingCode!}/${attachment.originalName!}`,
        this.bookingService.token!.key!,
        attachment.originalName!
      );
    } else {
      this.shipmentApiService
        .downloadShipmentAttachment(
          customerDatabaseId,
          this.shipmentInfo?.warehouseCode!,
          this.shipmentInfo?.bookingCode!,
          attachment.originalName!,
          this.bookingService.token!.key!
        )
        .subscribe((res) => {
          if (res) {
            var url = window.URL.createObjectURL(res);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display: none");
            a.href = url;
            a.download = attachment.originalName!;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
          }
        });
    }
  };

  downloadTicket = () => {
    if (this.shipmentInfo) {
      if (mobileAndTabletCheck()) {
        downloadFileCSharp(
          `${environment.baseApiUrl}/api/Shipment/DownloadShipmentCustomTicket/${this.shipmentInfo.bookingCode}`,
          this.bookingService.token!.key!,
          `${this.shipmentInfo.bookingCode}.pdf`
        );
      } else {
        this.shipmentApiService.downloadShipmentCustomTicket(this.shipmentInfo?.bookingCode!, this.bookingService.token!.key!).subscribe((res) => {
          if (res) {
            var url = window.URL.createObjectURL(res);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display: none");
            a.href = url;
            a.download = this.shipmentInfo?.bookingCode!;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
          }
        });
      }
    }
  };

  goToEditShipment = () => {
    if (this.shipmentInfo) {
      this.bookingService.resetBooking();
      this.router.navigate(["/edit/" + this.shipmentInfo?.bookingCode]);
    }
  };

  backToMyBookings = () => {
    if (mobileAndTabletCheck()) {
      goToMyBookings();
    } else {
      this.router.navigate(["/my-bookings"]);
    }
  };

  copyToClipboard = async (text: string | null | undefined) => {
    text && text != "" && (await navigator.clipboard.writeText(text));
  };
}
