import { CustomerPlantModelExtLMApi, ShipmentInfoLMApi } from "../api";
interface Window {
  isXamarinApp?: boolean;
}
interface ItemModel {
  Id: number;
  Quantity: number;
  Shipment_Id: number;
  Product_Code: string;
  Product_Description?: string;
  Destination_Code: string;
  Destination_Name?: string;
  DeliveryMethod?: string;
  TripType_Code: string;
  TripType_Name: string;
  Action: string;
}

interface PlantModel {
  Id: number;
  ExternalCode: string;
  Description: string;
  Lat: number;
  Lng: number;
  Address: string;
  City: string;
  CAP: string;
  State: string;
}

interface ShipmentModel {
  Id: number;
  Code: string;
  Status: number;
  Plant: PlantModel;
  PlantCode: string;
  StartDate: string;
  TruckType: string;
  Items: Array<ItemModel>;
  Notes: string;
  TruckPlate: string;
  TrailerPlate: string;
  PhoneNumber: string;
  Carrier: string;
  Email: string;
  InternalReference: string;
  Dock: string;
  ReferenceName: string;
  External: string;
  Sender: string;
  Receiver: string;
  FilePath: string;
}

export function addMinutes(date: Date, minutes: number): Date {
  let newDate = new Date(date.getTime() + minutes * 60000);
  return newDate;
}
export function addHours(date: Date, hours: number): Date {
  let newDate = new Date(date.getTime() + hours * 3600000);
  return newDate;
}
export function dateToUtcDate(date: Date): Date {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
}
export function getDateInTimezone(date: Date, timeZone: string): Date {
  // Using a locale of 'sv' formats as an ISO date, e.g. yyyy-MM-dd HH:mm.
  const timeInTimeZone = date.toLocaleString("sv", { timeZone: timeZone });
  // Pass this to the Date constructor
  return new Date(timeInTimeZone);
}
export function getMidnightUtc(date: Date): Date {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return new Date(date.toUTCString());
}
export function saveInLocalStorage(key: string, value: any) {
  localStorage.setItem(key, value);
}

export function fillFieldFromLocalStorage(element: string) {
  try {
    return localStorage.getItem(element) || null;
  } catch {
    return null;
  }
}

export const mobileAndTabletCheck = () => {
  let xamarin = (window as Window).isXamarinApp;
  return xamarin !== undefined;

  /*  
  let check = false;
  ((a) => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substring(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent);
  return check; */
};

export const mapForPostMessageShipmentModel = (info: ShipmentInfoLMApi, warehouse: CustomerPlantModelExtLMApi): ShipmentModel => {
  let res: ShipmentModel = {
    Id: 0,
    Code: info.bookingCode!,
    Status: info.currentStatusCode!,
    Plant: {
      Id: warehouse.id!,
      ExternalCode: warehouse.externalCode!,
      Description: warehouse.description!,
      Lat: warehouse.lat!,
      Lng: warehouse.lng!,
      Address: warehouse.address!,
      City: warehouse.city!,
      CAP: warehouse.cap!,
      State: warehouse.state!,
    },
    PlantCode: info.warehouseCode!,
    StartDate: info.bookingDate!,
    TruckType: info.truckType?.code!,
    Items: info.materials
      ? info.materials?.map(
        (m) =>
        ({
          Id: m.id,
          Quantity: m.quantity,
          Shipment_Id: 0,
          Product_Code: m.handlingUnit,
          Product_Description: "",
          Destination_Code: m.sourceDestination,
          Destination_Name: "",
          DeliveryMethod: m.shippingMode,
          TripType_Code: m.shippingMode,
          TripType_Name: "",
          Action: m.activityType,
        } as ItemModel)
      )
      : [],
    Notes: info.optionals?.notes!,
    TruckPlate: info.optionals?.truckPlate!,
    TrailerPlate: info.optionals?.trailerPlate!,
    PhoneNumber: info.optionals?.driverPhone!,
    Carrier: info.optionals?.carrierCode!,
    Email: info.email!,
    InternalReference: info.optionals?.referenceCode!,
    Dock: info.gateCode!,
    ReferenceName: info.optionals?.referenceName!,
    External: info.optionals?.external!,
    Sender: info.optionals?.sender!,
    Receiver: info.optionals?.receiver!,
    FilePath: "",
  };
  return res;
};

export type BaseTypes = string | object | Date;
export type SpreadObject = [string, string | object | Date];

export function dateDiff(date1: Date, date2: Date, granularity: 'seconds' | 'minutes' | 'hours' | 'days' = 'minutes') {
  try {
    const milliSecondsDiff = (date2.getMilliseconds() - date1.getMilliseconds());
    switch (granularity) {
      case 'seconds':
        return milliSecondsDiff / 1000;
      case 'minutes':
        return milliSecondsDiff / 1000 / 60;
      case 'hours':
        return milliSecondsDiff / 1000 / 60 / 60;
      case 'days':
        return milliSecondsDiff / 1000 / 60 / 60 / 24;
      default:
        throw new Error("granularity value is not valid");
    }
  } catch (exception){
    throw new Error(`dateDiff: ${exception}`)
  }
}