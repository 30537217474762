import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CodeNameOrderIconLMApi } from 'src/app/api';

@Pipe({
  name: 'getNameFromCode',
})
export class GetNameFromCodePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(value: string | null | undefined, types: CodeNameOrderIconLMApi[] | null | undefined, ...args: any[]): string | null | undefined {
    if (value) {
      let obj = types?.find((x) => x.code == value);
      return obj ? obj.name : '';
    }
    return '';
  }
}
