import { formatDate } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable } from "rxjs";

@Pipe({
  name: "dateToPlantTimezone",
})
export class DateToPlantTimezonePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(value: Date | string | null | undefined, format: string | null | undefined, timezone: string | null | undefined, ...args: any[]): Observable<string | undefined> {
    /*  if (value) {
      let lang = Intl.DateTimeFormat().resolvedOptions().locale;
      let date = formatDate(value, format ?? "yyyy-MM-dd HH:mm", lang, timezone ? timezone : "UTC");
      return date;
    } */
    let obs = new BehaviorSubject<string | undefined>(undefined);
    if (value) {
      this.translate.onLangChange.subscribe((x) => {
        let lang = this.translate.currentLang;
        let date = formatDate(value, format ?? "yyyy-MM-dd HH:mm", lang, timezone ? timezone : "UTC");
        obs.next(date);
      });
      let lang = this.translate.currentLang;
      let date = formatDate(value, format ?? "yyyy-MM-dd HH:mm", lang, timezone ? timezone : "UTC");
      obs.next(date);
    } else {
      obs.next("Invalid Date");
    }

    return obs.asObservable();
  }
}
