import { Component, Input, OnDestroy } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subject, filter, interval, map, switchMap, takeUntil } from "rxjs";
import { BookingLMApiService, CheckEmailRequestLMApi, EmailStatusLMApi, SendEmailRequestLMApi } from "src/app/api";

@Component({
  selector: "app-check-email-confirmation",
  template: `
    <div class="modal-header">
      <h3 class="modal-title">
        {{ title | translate }}
      </h3>
    </div>
    <div class="modal-body">
      <div>
        {{ body | translate }}
        <button type="button" class="btn btn-link" style="padding-left: 0; margin-top: -3px;" (click)="sendEmail()" [disabled]="waitSecs" *ngIf="resend">
          {{ "CHECK_SEND_MAIL" | translate }}
        </button>
      </div>
    </div>
    <div class="modal-footer justify-content-center p-0">
      <button type="button" class="btn btn-light btn-footer-mobile left" aria-label="Close" (click)="activeModal.close(false)">
        {{ "DIALOG_CLOSE" | translate }}
      </button>
      <button type="button" class="btn btn-primary btn-footer-mobile right" (click)="proceed()" *ngIf="resend">
        {{ "PROCEED" | translate }}
      </button>
    </div>
  `,
})
export class CheckEmailConfirmationComponent implements OnDestroy {
  @Input() email!: string;
  @Input() token!: string;
  @Input() plantCode!: string;
  @Input() title!: string;
  @Input() body!: string;
  @Input() resend: boolean = false;

  waitSecs: boolean = false;
  interval: Observable<boolean> = new Observable<boolean>();
  protected destroyEvent$: Subject<void> = new Subject();

  constructor(public activeModal: NgbActiveModal, public apiService: BookingLMApiService) {
    this.interval = interval(15000).pipe(
      map((x) => (this.waitSecs = false)),
      takeUntil(this.destroyEvent$)
    );
  }

  proceed() {
    let check: CheckEmailRequestLMApi = { email: this.email, plantCode: this.plantCode, isSendMail: false };
    this.apiService
      .checkEmail(this.token!, check)
      .pipe(filter((x) => !!x))
      .subscribe((x) => {
        if (x.emailStatus == EmailStatusLMApi.WhiteList) this.activeModal.close(true);
      });
  }

  sendEmail() {
    let input: SendEmailRequestLMApi = { email: this.email, plantCode: this.plantCode };
    this.apiService
      .sendEmail(this.token, input)
      .pipe(
        map((_) => (this.waitSecs = true)),
        switchMap((_) => this.interval)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroyEvent$.next();
    this.destroyEvent$.complete();
  }
}
