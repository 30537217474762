<ng-container *ngIf="loaded">
  <td class="p-0" *ngIf="typeActive?.visible">
    <ng-select
      [items]="typeActive?.types!"
      class="form-control p-0 rounded-0"
      [(ngModel)]="model.type"
      (ngModelChange)="update()"
      [ngClass]="{ 'is-invalid border border-danger border-3': type.invalid }"
      [required]="typeActive?.required!"
      [searchable]="true"
      [disabled]="!canEdit"
      #type="ngModel"
      name="type"
      bindLabel="name"
      autocomplete="off"
      style="height: 52px"
    >
      <ng-template ng-option-tmp let-item="item">
        <app-img-base64 [base64string]="item.icon"></app-img-base64>
        {{ item.name }}
      </ng-template>
    </ng-select>
  </td>
  <td class="p-0" *ngIf="configuration?.product?.visible">
    <ng-select
      [items]="configuration.product?.types!"
      class="form-control p-0 rounded-0"
      [(ngModel)]="model.product"
      (ngModelChange)="update()"
      [ngClass]="{ 'is-invalid border border-danger border-3': product.invalid }"
      [required]="configuration.product?.required!"
      [searchable]="true"
      [disabled]="!canEdit"
      #product="ngModel"
      name="product"
      bindLabel="name"
      autocomplete="off"
      style="height: 52px"
    >
      <ng-template ng-option-tmp let-item="item">
        <app-img-base64 [base64string]="item.icon"></app-img-base64>
        {{ item.name }}
      </ng-template>
    </ng-select>
  </td>
  <td class="p-0" *ngIf="configuration?.quantity?.visible">
    <input
      type="number"
      name="quantity"
      class="form-control rounded-0 shadow-none"
      [(ngModel)]="model.quantity"
      (ngModelChange)="update()"
      #quantity="ngModel"
      [ngClass]="{ 'is-invalid border border-danger border-3': quantity.invalid || (model.quantity && (model.quantity <= 0 || model.quantity > quantityLimit())) }"
      [required]="configuration.quantity?.required!"
      [disabled]="!canEdit"
      autocomplete="off"
      [min]="1"
      [max]="quantityLimit()"
      style="height: 52px"
    />
  </td>
  <td class="p-0" *ngIf="configuration?.deliveryMethod?.visible">
    <ng-select
      [items]="configuration.deliveryMethod?.types!"
      class="form-control p-0 rounded-0"
      [(ngModel)]="model.deliveryMethod"
      (ngModelChange)="update()"
      [ngClass]="{ 'is-invalid border border-danger border-3': deliveryMethod.invalid }"
      [required]="configuration.deliveryMethod?.required!"
      [disabled]="!canEdit"
      [searchable]="true"
      #deliveryMethod="ngModel"
      name="deliveryMethod"
      bindLabel="name"
      autocomplete="off"
      style="height: 52px"
    >
      <ng-template ng-option-tmp let-item="item">
        <app-img-base64 [base64string]="item.icon" [width30px]="true"></app-img-base64>
        {{ item.name }}
      </ng-template>
    </ng-select>
  </td>
  <td class="p-0" *ngIf="configuration?.sourceDestination?.visible">
    <ng-select
      [items]="configuration.sourceDestination?.types!"
      class="form-control p-0 rounded-0"
      [(ngModel)]="model.sourceDestination"
      (ngModelChange)="update()"
      [ngClass]="{ 'is-invalid border border-danger border-3': sourceDestination.invalid }"
      [required]="configuration.sourceDestination?.required!"
      [disabled]="!canEdit"
      [searchable]="true"
      #sourceDestination="ngModel"
      name="sourceDestination"
      bindLabel="name"
      autocomplete="off"
      style="height: 52px"
    >
      <ng-template ng-option-tmp let-item="item">
        {{ item.name }}
      </ng-template>
    </ng-select>
  </td>
  <td class="p-0">
    <button class="btn btn-danger btn-50-mobile delete-product-button rounded-0" style="height: 52px; width: 50px" (click)="removeRow()" [disabled]="!canEdit">
      <i class="bi bi-trash-fill"></i>
    </button>
  </td>
</ng-container>
