import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { LMApiConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { BookingLMApiService } from './api/booking.lmapi.service';
import { GeolocalizationLMApiService } from './api/geolocalization.lmapi.service';
import { OTPShipmentListLMApiService } from './api/oTPShipmentList.lmapi.service';
import { PreBookingLMApiService } from './api/preBooking.lmapi.service';
import { ShipmentLMApiService } from './api/shipment.lmapi.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class LMApiApiModule {
    public static forRoot(configurationFactory: () => LMApiConfiguration): ModuleWithProviders<LMApiApiModule> {
        return {
            ngModule: LMApiApiModule,
            providers: [ { provide: LMApiConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: LMApiApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('LMApiApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
