<div class="main-container mt-lg-3">
  <div class="inner-container overflow-y px-3 pb-4" *ngIf="loaded && shipmentInfo">
    <app-title-with-languages [title]="'VIEW_TITLE'"></app-title-with-languages>
    <div class="dm-container">
      <div class="d-flex flex-column flex-lg-row justify-content-between mt-3">
        <button class="btn btn-primary btn-50-mobile w-100 lg-30 mb-3" (click)="downloadTicket()">
          <i class="bi bi-file-earmark-arrow-down me-2"></i>{{ "VIEW_DOWNLOAD_TICKET" | translate }}
        </button>
        <button class="btn btn-primary btn-50-mobile w-100 lg-30 mb-3" (click)="goToEditShipment()"><i class="bi bi-pencil me-2"></i>{{ "EDIT_TITLE" | translate }}</button>
        <button class="btn btn-primary btn-50-mobile w-100 lg-30 mb-3" (click)="backToMyBookings()">
          <i class="bi bi-card-list me-2"></i>{{ "SHIPMENT_LIST_TITLE" | translate }}
        </button>
      </div>

      <div [innerHTML]="ticketString | safeHtml"></div>

      <div class="mb-3">
        <div *ngFor="let a of shipmentInfo.attachments1" class="d-flex flex-row chip justify-content-between">
          <div class="chip-icon-left"><i class="bi bi-paperclip"></i></div>
          <label class="chip-label flex-grow-1">{{ a.originalName }}</label>
          <div class="chip-icon-right" (click)="downloadAttachment(a)"><i class="bi bi-file-earmark-arrow-down-fill"></i></div>
        </div>
        <div *ngFor="let a of shipmentInfo.attachments2" class="d-flex flex-row chip justify-content-between">
          <div class="chip-icon-left"><i class="bi bi-paperclip"></i></div>
          <label class="chip-label flex-grow-1">{{ a.originalName }}</label>
          <div class="chip-icon-right" (click)="downloadAttachment(a)"><i class="bi bi-file-earmark-arrow-down-fill"></i></div>
        </div>
        <div *ngFor="let a of shipmentInfo.attachments3" class="d-flex flex-row chip justify-content-between">
          <div class="chip-icon-left"><i class="bi bi-paperclip"></i></div>
          <label class="chip-label flex-grow-1">{{ a.originalName }}</label>
          <div class="chip-icon-right" (click)="downloadAttachment(a)"><i class="bi bi-file-earmark-arrow-down-fill"></i></div>
        </div>
      </div>
    </div>
  </div>
  <div class="inner-container overflow-y px-3 pb-4 mt-3" *ngIf="!loaded">
    <div class="d-flex flex-column align-items-center">
      <div class="spinner-border mb-3" style="width: 4rem; height: 4rem" role="status"></div>
      {{ "LOADING_DATA" | translate }}
    </div>
  </div>
</div>

<!--  
        <div [hidden]="true">
        <div class="mb-2">{{ "VIEW_TEXT1" | translate }}</div>
        <h4 class="alert booking-code d-flex flex-column align-items-center">{{ shipmentInfo.bookingCode }}</h4>
        <div class="mb-2">{{ "VIEW_TEXT2" | translate }}</div>
        <div class="mb-2 d-flex flex-column align-items-center">
          <app-qr-code *ngIf="qrCode" [qrcode]="qrCode"></app-qr-code>
        </div>
      </div>
      <div class="alert alert-secondary text-break" role="alert" *ngIf="bookingService.selectedWarehouseData" [hidden]="true">
        <label class="fw-bold">{{ "DATA_DESTINATION" | translate }}</label>
        <div>{{ bookingService.selectedWarehouseData.description }}</div>
        <div>{{ bookingService.selectedWarehouseData.address }}</div>
        <div>{{ bookingService.selectedWarehouseData.city }}</div>
        <div>{{ bookingService.selectedWarehouseData.cap }}</div>
        <div
          class="d-flex flex-column"
          *ngIf="bookingService.warehouseConfiguration.date?.required || (shipmentInfo.bookingDate && bookingService.warehouseConfiguration.date?.visible)"
        >
          <label class="fw-bold">{{ bookingService.warehouseConfiguration.date?.labelTranslations | translateLabel | async }}</label>
          <div>{{ shipmentInfo.bookingDate | dateToPlantTimezone : "yyyy-MM-dd HH:mm" : bookingService.warehouseConfiguration.timezone | async }}</div>
        </div>
        <div class="d-flex flex-column">
          <label class="fw-bold">{{ "VIEW_TRACKINGCODE" | translate }}</label>
          <div *ngIf="shipmentInfo.trackingCode && shipmentInfo.trackingCode?.trim() != ''">{{ shipmentInfo.trackingCode }}</div>
          <div class="data-missing-value" *ngIf="!shipmentInfo.trackingCode || shipmentInfo.trackingCode?.trim() == ''">
            {{ "VIEW_MISSING_VALUE" | translate }}
          </div>
        </div>
        <div class="d-flex flex-column" *ngIf="mandatoryFieldsActive.driverPhone?.required || (shipmentInfo.optionals?.driverPhone && mandatoryFieldsActive.driverPhone?.visible)">
          <label class="fw-bold">{{ mandatoryFieldsActive.driverPhone?.labelTranslations | translateLabel | async }}</label>
          <div>{{ shipmentInfo.optionals?.driverPhone }}</div>
        </div>
        <div
          class="d-flex flex-column"
          *ngIf="bookingService.warehouseConfiguration.email?.required || (shipmentInfo.email && bookingService.warehouseConfiguration.email?.visible)"
        >
          <label class="fw-bold">{{ bookingService.warehouseConfiguration.email?.labelTranslations | translateLabel | async }}</label>
          <div>{{ shipmentInfo.email }}</div>
        </div>
        <div
          class="d-flex flex-column"
          *ngIf="bookingService.warehouseConfiguration.truckType?.required || (shipmentInfo.truckType && bookingService.warehouseConfiguration.truckType?.visible)"
        >
          <label class="fw-bold">{{ bookingService.warehouseConfiguration.truckType?.labelTranslations | translateLabel | async }}</label>
          <div>{{ shipmentInfo.truckType?.name }}</div>
        </div>
        <div class="d-flex flex-column" *ngIf="shipmentInfo.materials">
          <div class="d-flex flex-column" *ngFor="let a of shipmentInfo.materials">
            <label class="fw-bold">{{ a.activityType }}</label> {{ a.handlingUnit + " [" + a.quantity + "]" }}
          </div>
        </div>
        <div class="d-flex flex-column" *ngIf="mandatoryFieldsActive.carrier?.visible">
          <label class="fw-bold">{{ mandatoryFieldsActive.carrier?.labelTranslations | translateLabel | async }}</label>
          <div *ngIf="shipmentInfo.optionals?.carrierCode && shipmentInfo.optionals?.carrierCode?.trim() != ''">{{ shipmentInfo.optionals?.carrierCode }}</div>
          <div class="data-missing-value" *ngIf="!shipmentInfo.optionals?.carrierCode || shipmentInfo.optionals?.carrierCode?.trim() == ''">
            {{ "VIEW_MISSING_VALUE" | translate }}
          </div>
        </div>
        <div class="d-flex flex-column" *ngIf="mandatoryFieldsActive.sender?.visible">
          <label class="fw-bold">{{ mandatoryFieldsActive.sender?.labelTranslations | translateLabel | async }}</label>
          <div *ngIf="shipmentInfo.optionals?.sender && shipmentInfo.optionals?.sender?.trim() != ''">{{ shipmentInfo.optionals?.sender }}</div>
          <div class="data-missing-value" *ngIf="!shipmentInfo.optionals?.sender || shipmentInfo.optionals?.sender?.trim() == ''">{{ "VIEW_MISSING_VALUE" | translate }}</div>
        </div>
        <div class="d-flex flex-column" *ngIf="mandatoryFieldsActive.receiver?.visible">
          <label class="fw-bold">{{ mandatoryFieldsActive.receiver?.labelTranslations | translateLabel | async }}</label>
          <div *ngIf="shipmentInfo.optionals?.receiver && shipmentInfo.optionals?.receiver?.trim() != ''">{{ shipmentInfo.optionals?.receiver }}</div>
          <div class="data-missing-value" *ngIf="!shipmentInfo.optionals?.receiver || shipmentInfo.optionals?.receiver?.trim() == ''">{{ "VIEW_MISSING_VALUE" | translate }}</div>
        </div>
        <div class="d-flex flex-column" *ngIf="mandatoryFieldsActive.refName?.visible">
          <label class="fw-bold">{{ mandatoryFieldsActive.refName?.labelTranslations | translateLabel | async }}</label>
          <div *ngIf="shipmentInfo.optionals?.referenceName && shipmentInfo.optionals?.referenceName?.trim() != ''">{{ shipmentInfo.optionals?.referenceName }}</div>
          <div class="data-missing-value" *ngIf="!shipmentInfo.optionals?.referenceName || shipmentInfo.optionals?.referenceName?.trim() == ''">
            {{ "VIEW_MISSING_VALUE" | translate }}
          </div>
        </div>
        <div class="d-flex flex-column" *ngIf="mandatoryFieldsActive.refNumber?.visible">
          <label class="fw-bold">{{ mandatoryFieldsActive.refNumber?.labelTranslations | translateLabel | async }}</label>
          <div *ngIf="shipmentInfo.optionals?.referenceCode && shipmentInfo.optionals?.referenceCode?.trim() != ''">{{ shipmentInfo.optionals?.referenceCode }}</div>
          <div class="data-missing-value" *ngIf="!shipmentInfo.optionals?.referenceCode || shipmentInfo.optionals?.referenceCode?.trim() == ''">
            {{ "VIEW_MISSING_VALUE" | translate }}
          </div>
        </div>
        <div class="d-flex flex-column" *ngIf="mandatoryFieldsActive.secRefNumber?.visible">
          <label class="fw-bold">{{ mandatoryFieldsActive.secRefNumber?.labelTranslations | translateLabel | async }}</label>
          <div *ngIf="shipmentInfo.optionals?.external && shipmentInfo.optionals?.external?.trim() != ''">{{ shipmentInfo.optionals?.external }}</div>
          <div class="data-missing-value" *ngIf="!shipmentInfo.optionals?.external || shipmentInfo.optionals?.external?.trim() == ''">{{ "VIEW_MISSING_VALUE" | translate }}</div>
        </div>
        <div class="d-flex flex-column" *ngIf="mandatoryFieldsActive.truckPlate?.visible">
          <label class="fw-bold">{{ mandatoryFieldsActive.truckPlate?.labelTranslations | translateLabel | async }}</label>
          <div *ngIf="shipmentInfo.optionals?.truckPlate && shipmentInfo.optionals?.truckPlate?.trim() != ''">{{ shipmentInfo.optionals?.truckPlate }}</div>
          <div class="data-missing-value" *ngIf="!shipmentInfo.optionals?.truckPlate || shipmentInfo.optionals?.truckPlate?.trim() == ''">
            {{ "VIEW_MISSING_VALUE" | translate }}
          </div>
        </div>
        <div class="d-flex flex-column" *ngIf="mandatoryFieldsActive.trailerPlate?.visible">
          <label class="fw-bold">{{ mandatoryFieldsActive.trailerPlate?.labelTranslations | translateLabel | async }}</label>
          <div *ngIf="shipmentInfo.optionals?.trailerPlate && shipmentInfo.optionals?.trailerPlate?.trim() != ''">{{ shipmentInfo.optionals?.trailerPlate }}</div>
          <div class="data-missing-value" *ngIf="!shipmentInfo.optionals?.trailerPlate || shipmentInfo.optionals?.trailerPlate?.trim() == ''">
            {{ "VIEW_MISSING_VALUE" | translate }}
          </div>
        </div>
        <div class="d-flex flex-column" *ngIf="mandatoryFieldsActive.note?.visible">
          <label class="fw-bold">{{ mandatoryFieldsActive.note?.labelTranslations | translateLabel | async }}</label>
          <div *ngIf="shipmentInfo.optionals?.notes && shipmentInfo.optionals?.notes?.trim() != ''">{{ shipmentInfo.optionals?.notes }}</div>
          <div class="data-missing-value" *ngIf="!shipmentInfo.optionals?.notes || shipmentInfo.optionals?.notes?.trim() == ''">{{ "VIEW_MISSING_VALUE" | translate }}</div>
        </div>
      </div> 
      <div class="alert alert-secondary text-break" role="alert" *ngIf="bookingService.selectedWarehouseData">
        <div class="d-flex flex-column" *ngIf="mandatoryFieldsActive.attachments1?.visible">
          <label class="fw-bold">{{ mandatoryFieldsActive.attachments1?.labelTranslations | translateLabel | async }}</label>
          <div *ngFor="let a of shipmentInfo.attachments1" class="d-flex flex-row chip justify-content-between">
            <div class="chip-icon-left"><i class="bi bi-paperclip"></i></div>
            <label class="chip-label flex-grow-1">{{ a.originalName }}</label>
            <div class="chip-icon-right" (click)="downloadAttachment(a)"><i class="bi bi-file-earmark-arrow-down-fill"></i></div>
          </div>
          <div class="data-missing-value" *ngIf="!shipmentInfo.attachments1 || shipmentInfo.attachments1.length == 0">{{ "VIEW_MISSING_VALUE" | translate }}</div>
        </div>
        <div class="d-flex flex-column" *ngIf="mandatoryFieldsActive.attachments2?.visible">
          <label class="fw-bold">{{ mandatoryFieldsActive.attachments2?.labelTranslations | translateLabel | async }}</label>
          <div *ngFor="let a of shipmentInfo.attachments2" class="d-flex flex-row chip justify-content-between">
            <div class="chip-icon-left"><i class="bi bi-paperclip"></i></div>
            <label class="chip-label flex-grow-1">{{ a.originalName }}</label>
            <div class="chip-icon-right" (click)="downloadAttachment(a)"><i class="bi bi-file-earmark-arrow-down-fill"></i></div>
          </div>
          <div class="data-missing-value" *ngIf="!shipmentInfo.attachments2 || shipmentInfo.attachments2.length == 0">{{ "VIEW_MISSING_VALUE" | translate }}</div>
        </div>
        <div class="d-flex flex-column" *ngIf="mandatoryFieldsActive.attachments3?.visible">
          <label class="fw-bold">{{ mandatoryFieldsActive.attachments3?.labelTranslations | translateLabel | async }}</label>
          <div *ngFor="let a of shipmentInfo.attachments3" class="d-flex flex-row chip justify-content-between">
            <div class="chip-icon-left"><i class="bi bi-paperclip"></i></div>
            <label class="chip-label flex-grow-1">{{ a.originalName }}</label>
            <div class="chip-icon-right" (click)="downloadAttachment(a)"><i class="bi bi-file-earmark-arrow-down-fill"></i></div>
          </div>
          <div class="data-missing-value" *ngIf="!shipmentInfo.attachments3 || shipmentInfo.attachments3.length == 0">{{ "VIEW_MISSING_VALUE" | translate }}</div>
        </div>
      </div> -->
