import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbDateUtcAdapter extends NgbDateAdapter<Date> {
  fromModel(date: Date): NgbDateStruct | null {
    return date && date.getFullYear
      ? ({
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
        } as NgbDateStruct)
      : null;
  }

  toModel(date: NgbDateStruct | null): Date | null {
    var dateUTC = date ? new Date(Date.UTC(date.year, date.month - 1, date.day, 0, 0, 0, 0)) : null;
    return dateUTC;
  }
}
