<header>
  <nav
    class="navbar w-100 navbar-expand-sm navbar-toggleable-sm bg-primary navbar-dark box-shadow pt-0 d-flex flex-row"
    [style.background-color]="(topBarService.color | async) + ' !important'"
  >
    <a class="navbar-brand" (click)="goToLMHome()">
      <img *ngIf="!(topBarService.icon | async)" src="assets/logo.svg" />
      <img *ngIf="topBarService.icon | async" [src]="topBarService.icon | async" />
    </a>
    <div class="flex-grow-1"></div>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-end">
        <a class="nav-link text-light" style="cursor: pointer" ngbDropdownToggle id="navbarDropdown" role="button">
          <span id="hamburger-icon" [ngStyle]="{ color: hamburgerColor }">☰</span>
        </a>
        <div ngbDropdownMenu aria-labelledby="navbarDropdown" class="dropdown-menu dropdown-menu-right">
          <form class="px-3 search">
            <div class="input-group input-group-sm">
              <input
                class="form-control form-control-sm"
                name="search-bookings"
                type="text"
                [attr.placeholder]="'SHIPMENT_LIST_SEARCH_BOOKINGS' | translate"
                [(ngModel)]="search"
              />
              <div class="input-group-append">
                <button class="btn btn-primary" type="button" (click)="edit()"><i class="glyphicon bi bi-search"></i></button>
              </div>
            </div>
          </form>
          <div class="dropdown-divider"></div>
          <!--   <div ngbDropdownItem class="iconlink px-3">
            <i class="glyphicon bi bi-house-fill"></i>
            <a class="text-light text-center" [href]="url">{{ "HOME" | translate }}</a>
          </div> -->
          <div ngbDropdownItem class="iconlink px-3">
            <i class="glyphicon bi bi-list-task"></i>
            <a class="text-light text-center" [href]="myBookingUrl">{{ "SHIPMENT_LIST_TITLE" | translate }}</a>
          </div>
          <div class="falsedropdown-item iconlink px-3" (click)="language($event)">
            <i class="glyphicon bi bi-globe-americas"></i>
            <span class="text-light">{{ "LANGUAGE" | translate }}</span>
            <span class="caret"></span>
          </div>
          <div ngbDropdown #langMenu="ngbDropdown">
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
              <a ngbDropdownItem class="text-light" (click)="setLang(l.id)" *ngFor="let l of languages">
                <img [src]="'assets/flags/' + l.file" height="20" width="30" class="me-1 object-fit-cover border" />
                {{ l.name }}
              </a>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</header>
