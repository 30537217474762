import { GetWarehouseHoursListByDateRequestLMApi } from "../../../api/model/getWarehouseHoursListByDateRequest.lmapi";
import { Component, NgZone, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbTypeaheadSelectItemEvent } from "@ng-bootstrap/ng-bootstrap";
import * as Leaflet from "leaflet";
import "leaflet.markercluster";
import { OperatorFunction, Observable, debounceTime, distinctUntilChanged, map } from "rxjs";
import { BookingService } from "src/app/services/booking.service";
import * as _ from "lodash";
import { BookingLMApiService, CustomerPlantConfigurationModelExtLMApi, CustomerPlantModelExtLMApi, GetWarehousesLinkedByCustomerResponseLMApi } from "src/app/api";
import { saveInLocalStorage, fillFieldFromLocalStorage } from "src/app/services/utils";
import { LeafletInfoDialogComponent } from "src/app/dialogs/leaflet-info-dialog/leaflet-info-dialog.component";
import { TopBarService } from "src/app/services/topbar.service";
import { PrivacyInitialDialogComponent } from "src/app/dialogs/privacy-initial-dialog/privacy-initial-dialog.component";
Leaflet.Icon.Default.imagePath = "assets/";

@Component({
  selector: "app-book-by-customer",
  templateUrl: "./book-by-customer.component.html",
})
export class BookByCustomerComponent implements OnInit {
  loaded: boolean = false;
  customerCode: string | undefined;
  map!: Leaflet.Map;
  markers: Leaflet.Marker[] = [];
  warehousesList: CustomerPlantModelExtLMApi[] | null = [];
  searchText: any;
  options = {
    layers: [
      Leaflet.tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        //'https://maps.loadmanager.cloud/osm_tiles/{z}/{x}/{y}.png'
      ),
    ],
    //'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'),
    zoom: 16,
    center: { lat: 28.626137, lng: 79.821603 },
  };

  // Marker cluster stuff
  markerClusterGroup: Leaflet.MarkerClusterGroup = new Leaflet.MarkerClusterGroup();
  markerClusterData: any[] = [];
  markerClusterOptions: Leaflet.MarkerClusterGroupOptions | undefined;

  now = new Date();

  constructor(
    private route: ActivatedRoute,
    private zone: NgZone,
    private modal: NgbModal,
    public bookingService: BookingService,
    private router: Router,
    private apiService: BookingLMApiService,
    private topBarService: TopBarService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap) => {
      this.customerCode = paramMap.get("customerCode") ?? undefined;
      let lang = paramMap.get("lang");

      //GESTIONE LINGUA
      let localStorageLang = localStorage.getItem("lang");
      if (lang) this.bookingService.lang = lang;
      else if (localStorageLang) this.bookingService.lang = localStorageLang;

      //GESTIRE PRIVACY
      let localStoragePrivacy = localStorage.getItem("load_manager_privacy_version");
      if (!localStoragePrivacy) {
        let privacyRef = this.modal.open(PrivacyInitialDialogComponent, { centered: true, backdrop: "static", keyboard: false });
      }

      //GESTIONE STEPPER
      let url = this.router.url.substring(1);
      this.bookingService.setStepper([
        { label: "1", url: url },
        { label: "2", url: url + "/new", desktopPhase: 0 },
        { label: "3", url: url + "/new", desktopPhase: 1 },
        { label: "4", url: url + "/new/slot" },
        { label: "END", url: "" },
      ]);
      this.bookingService.setStepperActive(0);

      //SALVATAGGIO URL DI PARTENZA
      if (!sessionStorage['base_url'] || sessionStorage['base_url'] != location.href){
        sessionStorage.setItem('base_url', location.href);
      }
      //this.topBarService.url$.next(sessionStorage['base_url'])

      this.apiService.getWarehousesLinkedByCustomer(this.customerCode!).subscribe((res: GetWarehousesLinkedByCustomerResponseLMApi) => {
        if (res && res.plants) {
          // CAMBIO COLORE E ICONA
          res.icon && this.topBarService.icon.next(res.icon);
          res.color && this.topBarService.color.next(res.color);
          this.bookingService.warehousesList = res.plants;
        } else this.bookingService.warehousesList = [];
        this.warehousesList = this.bookingService.warehousesList;
        this.initMarkers();
      });
    });
    this.loaded = true;
  }

  search: OperatorFunction<string, readonly (string | null | undefined)[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        this.warehousesList
          ? this.warehousesList
              .map((x) => x.description)
              .filter((v) => (v ? v.toLowerCase().indexOf(term.toLowerCase()) > -1 : false))
              .slice(0, 10)
          : []
      )
    );
  selectSearch = (event: NgbTypeaheadSelectItemEvent<any>) => {
    let description = event.item;
    let warehouseSelected = this.warehousesList?.find((x) => x.description == description);
    if (warehouseSelected) this.map.panTo(new Leaflet.LatLng(warehouseSelected.lat!, warehouseSelected.lng!));
  };

  initMarkers() {
    const defaultIcon = Leaflet.icon({
      iconUrl: "assets/hub.png",
      iconSize: [64, 37],
    });
    this.warehousesList?.map((data) => {
      const marker = Leaflet.marker({ lat: data.lat!, lng: data.lng! }, { icon: defaultIcon });
      marker.on("click", () => {
        this.zone.run(() => this.showPopup(data)); //error
      });
      this.markerClusterGroup.addLayer(marker);
    });
    this.map.attributionControl.setPrefix(false);
    this.map.addLayer(this.markerClusterGroup);

    let coordinatesInLocalStorage = fillFieldFromLocalStorage("lastWarehouseSelected");
    //Posizionamento Mappa default: Se già selezionato un magazzino punto quello, altrimenti il primo magazzino della lista
    if (this.bookingService.selectedWarehouseData) {
      this.map.panTo({
        lat: this.bookingService.selectedWarehouseData.lat!,
        lng: this.bookingService.selectedWarehouseData.lng!,
      });
    } else if (coordinatesInLocalStorage) {
      let json = JSON.parse(coordinatesInLocalStorage);
      this.map.panTo(json);
    } else {
      this.map.panTo(
        this.warehousesList
          ? {
              lat: this.warehousesList[0].lat!,
              lng: this.warehousesList[0].lng!,
            }
          : { lat: 0, lng: 0 }
      );
    }
  }
  markerClusterReady(group: Leaflet.MarkerClusterGroup) {
    this.markerClusterGroup = group;
  }
  onMapReady($event: Leaflet.Map) {
    this.map = $event;
  }

  showPopup(data: CustomerPlantModelExtLMApi): void {
    const leafletInfoDialogRef = this.modal.open(LeafletInfoDialogComponent, { centered: true });
    leafletInfoDialogRef.componentInstance.data = data;
    leafletInfoDialogRef.closed.subscribe((res: any) => {
      if (res) {
        this.bookingService.selectedWarehouseData = data;
        this.goNext();
      }
    });
  }
  goNext = () => {
    saveInLocalStorage("lastWarehouseSelected", JSON.stringify({ lat: this.bookingService.selectedWarehouseData?.lat, lng: this.bookingService.selectedWarehouseData?.lng }));
    // Chiamata per recuperare la configurazione della warehouse selezionata.
    if (this.bookingService.selectedWarehouseData?.token) {
      this.loaded = false;
      this.bookingService.resetModel();
      this.apiService.getWarehouseConfiguration(this.bookingService.selectedWarehouseData?.token).subscribe((x: CustomerPlantConfigurationModelExtLMApi) => {
        this.bookingService.warehouseConfiguration = x;
        this.bookingService.checkConfiguration(x);
        // RESETTARE TUTTI I CAMPI ATTUALMENTE INSERITI IN PRENOTAZIONE
        this.router.navigate(["new"], { relativeTo: this.route });
      });
    }
  };
}
