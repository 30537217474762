import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { IBookStep } from "src/app/models/interfaces";
import { BookingService } from "src/app/services/booking.service";

@Component({
  selector: "app-booking-stepper",
  template: `
    <div class="stepper-wrapper mt-3">
      <div *ngFor="let step of steps; let i = index" class="stepper-item" [ngClass]="active > i ? 'completed' : active === i ? 'active' : ''">
        <div class="step-counter" (click)="active > i && (!disabled && !step.disabled) && goToStep(i, step.url, step.desktopPhase)" [ngClass]="active > i && (!disabled && !step.disabled) ? 'cursor-pointer' : ''"></div>
        <div class="step-name">{{ step.label }}</div>
      </div>
    </div>
  `,
})
export class BookingStepperComponent implements OnDestroy {
  active: number = 1;
  steps: IBookStep[] = [];
  sub: Subscription;
  disabled: boolean | undefined;
  constructor(private bookingService: BookingService, private router: Router) {
    this.sub = this.bookingService.$stepper.subscribe((s) => {
      this.steps = s.steps;
      this.active = s.active;
      this.disabled = s.disabled;
    });
  }

  goToStep(index: number, url: string, desktopPhase: number | undefined) {
    if (desktopPhase === 0) {
      this.bookingService.desktopStep = 0;
      this.bookingService.setStepperActive(index);
    }
    if (desktopPhase === 1) {
      this.bookingService.desktopStep = 1;
      this.bookingService.setStepperActive(index);
    }
    this.router.navigate([url]);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
