import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-delete-booking-dialog",
  template: `
    <div class="modal-header">
      <h3 class="modal-title">
        {{ "DELETE_SHIPMENT" | translate }}
      </h3>
    </div>
    <div class="modal-body">
      {{ "DELETE_SHIPMENT_TEXT" | translate }}
    </div>
    <div class="modal-footer justify-content-center p-0">
      <button type="button" class="btn btn-light btn-footer-mobile left" aria-label="Close" (click)="activeModal.dismiss()">
        {{ "DIALOG_CLOSE" | translate }}
      </button>
      <button type="button" class="btn btn-danger btn-footer-mobile right" (click)="delete()">
        {{ "DIALOG_DELETE_CONFIRM" | translate }}
      </button>
    </div>
  `,
})
export class DeleteBookingDialogComponent {
  constructor(public activeModal: NgbActiveModal) {}

  delete = () => {
    this.activeModal.close(true);
  };
}
