/**
 * api.loadmanager.cloud
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ActionTypeLMApi = 'None' | 'Load' | 'Unload' | 'LoadUnload';

export const ActionTypeLMApi = {
    None: 'None' as ActionTypeLMApi,
    Load: 'Load' as ActionTypeLMApi,
    Unload: 'Unload' as ActionTypeLMApi,
    LoadUnload: 'LoadUnload' as ActionTypeLMApi
};

