<div class="main-container">
  <app-title-with-languages class="mt-3" [title]="'SHIPMENT_LIST_TITLE'"></app-title-with-languages>
  <div class="inner-container overflow-y mt-3" *ngIf="!loaded && !showShipment">
    <div class="d-flex flex-column align-items-center">
      <div class="spinner-border mb-3" style="width: 4rem; height: 4rem" role="status"></div>
      {{ "LOADING_DATA" | translate }}
    </div>
  </div>
  <div [hidden]="!(!showShipment && loaded)">
    <div class="inner-container overflow-y mt-3 px-4 d-flex flex-column align-items-center">
      <form class="d-flex flex-column dm-container" style="max-width: 600px">
        <div [hidden]="insertCode">
          <div class="mb-3 d-flex flex-column">
            <label class="mb-2">{{ "SHIPMENT_LIST_SELECT_TYPE" | translate }}</label>
            <div class="form-group btn-group btn-group-justified btn-group-50-mobile align-items-center" role="group">
              <input type="radio" class="btn-check" name="btnradio" id="btnradio2" [formControl]="mode" [value]="EMAIL_MODE" />
              <label class="btn btn-outline-primary w-50" for="btnradio2">{{ "SHIPMENT_LIST_EMAIL" | translate }}</label>
              <input type="radio" class="btn-check" name="btnradio" id="btnradio1" [formControl]="mode" [value]="PHONE_MODE" />
              <label class="btn btn-outline-primary w-50" for="btnradio1">{{ "SHIPMENT_LIST_PHONE_NUMBER" | translate }}</label>
            </div>
          </div>
          <div [hidden]="mode.value != EMAIL_MODE">
            <div class="mb-3 d-flex flex-column form-group">
              <label class="mb-2">{{ "SHIPMENT_LIST_EMAIL_TEXT" | translate }}</label>
              <input type="email" class="form-control" [formControl]="email" placeholder="email@example.com" />
            </div>
          </div>
          <div [hidden]="mode.value != PHONE_MODE">
            <div class="mb-3 d-flex flex-column form-group">
              <label class="mb-2"> {{ "SHIPMENT_LIST_PHONE_TEXT" | translate }}</label>
              <input
                type="tel"
                #driverPhoneInput
                id="driverPhoneInput"
                name="driverPhone"
                class="form-control"
                autocomplete="off"
                [placeholder]="' '"
                [ngClass]="{ 'is-invalid': !intlValid }"
              />
            </div>
          </div>
          <button type="button" class="btn btn-primary btn-50-mobile w-100" (click)="verificationProcess()" [disabled]="!email.valid">
            {{ "SHIPMENT_LIST_SEND_CODE" | translate }}
          </button>
        </div>

        <div [hidden]="!insertCode">
          <div class="alert alert-warning d-flex flex-row" role="alert">
            <i class="bi bi-exclamation-triangle-fill me-3"></i>
            <div>{{ "SHIPMENT_LIST_VERIFY_TEXT1" | translate }}</div>
          </div>
          <div class="mb-3">
            <label class="form-label">{{ "SHIPMENT_LIST_VERIFY_CODE" | translate }}</label>
            <input type="text" class="form-control" [formControl]="code" />
            <label class="">{{ "SHIPMENT_LIST_VERIFY_TEXT2" | translate }}</label>
          </div>
          <button type="button" class="btn btn-primary btn-50-mobile w-100" (click)="verifyCode()" [disabled]="!code.value || (code.value && code.value === '')">
            {{ "SHIPMENT_LIST_VERIFY_COMPLETE" | translate }}
          </button>
        </div>
        <!--  <div class="alert alert-danger text-break mt-3" *ngIf="error">
          <label class="fw-bold">{{ error | translate }}</label>
        </div> -->
      </form>
    </div>
  </div>

  <div class="inner-container mt-3" [hidden]="!showShipment">
    <!--MOBILE: visibile per display xs sm-->
    <div class="dm-container h-100 flex-column list-group" [hidden]="!(bookingService.isScreenSmall$ | async)">
      <div class="input-group d-flex flex-row align-items-center justify-content-center mb-3" style="min-width: 0">
        <div class="user-logged-text text-truncate">{{ localStorageInfos?.email }}</div>
        <button type="button" class="btn btn-danger text-white" (click)="logout()"><i class="bi bi-box-arrow-right my-auto"></i></button>
      </div>
      <div class="overflow-y flex-grow-1 h-0" *ngIf="list && list.length > 0">
        <a
          *ngFor="let x of list"
          class="list-group-item list-group-item-action list-group-item-light cursor-pointer align-items-center d-flex flex-row rounded-0 border-start-0 border-end-0"
          (click)="details(x)"
        >
          <div class="d-flex flex-column flex-grow-1" style="min-width: 0">
            <div class="d-flex w-100">
              <h6 class="mb-0 me-2">{{ x.bookingCode }}</h6>
              <small class="text-truncate">{{ x.date | date : "dd/MM/yyyy HH:mm" }}</small>
            </div>
            <div class="d-flex flex-row w-100 justify-content-between">
              <p class="mb-0 d-inline-block text-truncate flex-grow-1">{{ x.warehouse }}</p>
              <div class="d-flex flex-row ms-3">
                <i class="bi bi-clock-fill icon-color-red" *ngIf="!x.onTime"></i>
                <i class="bi bi-exclamation-triangle-fill icon-color-red" *ngIf="x.optionalMissing"></i>
              </div>
            </div>
            <p class="mb-0 d-inline-block text-truncate flex-grow-1">{{ x.email }}</p>
          </div>
          <!-- <div class="ps-3">
          <button type="button" class="btn btn-primary text-white" (click)="details(x); $event.stopPropagation()"><i class="bi bi-search my-auto"></i></button>
        </div> -->
        </a>
      </div>
      <div *ngIf="!list || (list && list.length == 0)" class="list-group-item rounded-0 border-start-0 border-end-0 list-group-item-action align-items-center d-flex flex-row">
        {{ "NO_DATA_FOUND" | translate }}
      </div>
    </div>

    <!--DESKTOP: visibile per display md lg xl-->
    <div class="dm-container h-100" [hidden]="bookingService.isScreenSmall$ | async">
      <div class="px-3 h-100 d-flex flex-column">
        <div class="input-group d-flex flex-row align-items-center justify-content-center mb-3">
          <div class="input-group-text"><i class="bi bi-person-fill"></i></div>
          <div class="user-logged-text">{{ localStorageInfos?.email }}</div>
          <button type="button" class="btn btn-danger text-white" (click)="logout()">
            <i class="bi bi-box-arrow-right my-auto me-3"></i>{{ "SHIPMENT_LIST_USER_LOGOUT" | translate }}
          </button>
        </div>
        <div class="table-container flex-grow-1 overflow-y h-0 mb-3">
          <table class="table table-striped mb-0">
            <thead class="sticky-top">
              <tr class="table-light">
                <th scope="col">Email</th>
                <th scope="col">Booking code</th>
                <th scope="col">Warehouse</th>
                <th scope="col">Date</th>
                <th scope="col">On Time</th>
                <th scope="col">Optionals</th>
                <th scope="col" style="width: 60px"></th>
              </tr>
            </thead>
            <tbody class="">
              <tr *ngFor="let x of list">
                <td class="align-middle">{{ x.email }}</td>
                <td class="align-middle">{{ x.bookingCode }}</td>
                <td class="align-middle">{{ x.warehouse }}</td>
                <td class="align-middle">{{ x.date | date : "dd/MM/yyyy HH:mm" }}</td>
                <td class="align-middle fs-4">
                  <i class="bi bi-clock-fill icon-color-green" *ngIf="x.onTime"></i>
                  <i class="bi bi-clock-fill icon-color-red" *ngIf="!x.onTime"></i>
                </td>
                <td class="align-middle fs-4">
                  <i class="bi bi-check-circle-fill icon-color-green" *ngIf="!x.optionalMissing"></i>
                  <i class="bi bi-exclamation-triangle-fill icon-color-red" *ngIf="x.optionalMissing"></i>
                </td>
                <td style="width: 60px">
                  <button type="button" class="btn btn-primary text-white" (click)="details(x)"><i class="bi bi-search my-auto"></i></button>
                </td>
              </tr>
              <tr *ngIf="!list || (list && list.length == 0)">
                <td [colSpan]="7">{{ "NO_DATA_FOUND" | translate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
