import { TopBarService } from "../../services/topbar.service";
import { HttpErrorResponse } from "@angular/common/http";
import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, of, Subject, switchMap, takeUntil } from "rxjs";
import { OTPShipmentListLMApiService, OTPShipmentLMApi } from "../../api";
import * as intlTelInput from "intl-tel-input";
import { v4 as uuidv4 } from "uuid";
import { BookingService } from "src/app/services/booking.service";

@Component({
  selector: "app-my-bookings",
  templateUrl: "./my-bookings.component.html",
})
export class MyBookingsComponent implements AfterViewInit, OnDestroy {
  destroy = new Subject<void>();

  showShipment = false;
  insertCode = false;
  loaded = false;

  email = new FormControl<string | null>(null, Validators.required);
  code = new FormControl<string | null>(null);

  localStorageInfos: OTPLocalStorageDatas | null = null;
  localStorageInfosKey = "OTPLocalStorageDatas";

  list: OTPShipmentLMApi[] = [];

  PHONE_MODE = "phone";
  EMAIL_MODE = "email";
  mode = new FormControl(this.PHONE_MODE);
  intlValid: boolean = true;
  error: string | null = null;
  @ViewChild("driverPhoneInput") driverPhoneInput!: ElementRef;

  constructor(
    params: ActivatedRoute,
    private api: OTPShipmentListLMApiService,
    public bookingService: BookingService,
    private router: Router,
    private topBarService: TopBarService
  ) {
    let lang = params.snapshot.paramMap.get("lang")!;
    //GESTIONE LINGUA
    let localStorageLang = localStorage.getItem("lang");
    if (localStorageLang) this.bookingService.lang = localStorageLang;
    else if (lang) this.bookingService.lang = lang;
    this.topBarService.resetTopBar();

    let json = localStorage.getItem(this.localStorageInfosKey);
    this.localStorageInfos = json == null ? null : JSON.parse(json);
    if (!this.localStorageInfos) {
      this.localStorageInfos = { email: null, sessionId: uuidv4(), token: null };
    }
    this.email.setValue(this.localStorageInfos.email);
    this.mode.setValue(this.localStorageInfos.email == null || this.localStorageInfos.email.indexOf("@") >= 0 ? this.EMAIL_MODE : this.PHONE_MODE);
    this.mode.valueChanges.pipe(takeUntil(this.destroy)).subscribe((_) => this.email.setValue(null));
    if (this.localStorageInfos && this.localStorageInfos.email && this.localStorageInfos.sessionId && this.localStorageInfos.token) {
      this.getShipments();
    } else {
      this.loaded = true;
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  ngAfterViewInit(): void {
    let driverPhoneInput = this.driverPhoneInput.nativeElement;
    let numberComponent = intlTelInput(driverPhoneInput!, {
      utilsScript: "assets/intl/utils.js",
      initialCountry: "it",
      separateDialCode: true,
      preferredCountries: ["it", "gb", "es", "fr", "de", "pl", "ro", "ru"],
    });
    const handleChange = () => {
      if (numberComponent?.isValidNumber()) {
        this.intlValid = true;
        this.email.setValue(numberComponent?.getNumber());
      } else {
        this.intlValid = false;
      }
    };
    // listen to "keyup", but also "change" to update when the user selects a country
    driverPhoneInput.addEventListener("change", handleChange);
    driverPhoneInput.addEventListener("keyup", handleChange);
  }

  verificationProcess() {
    this.error = null;
    this.api
      .sendVerificationCode({
        email: this.mode.value === this.EMAIL_MODE ? this.email.value : null,
        phoneNumber: this.mode.value === this.PHONE_MODE ? this.email.value : null,
        sessionId: this.localStorageInfos?.sessionId,
      })
      .subscribe((res) => {
        if (res === 0) {
          this.error = "SHIPMENT_LIST_UNKNOWNERROR";
        } else if (res === 1) {
          this.code.setValue(null);
          this.insertCode = true;
        }
      });
  }

  getShipments() {
    this.insertCode = false;
    this.error = null;
    this.api
      .getShipments({ email: this.email.value, token: this.localStorageInfos?.token, sessionId: this.localStorageInfos?.sessionId })
      .pipe(
        catchError((x: HttpErrorResponse) => {
          console.log("ERROR: ", x);
          if (x.status == 401) {
            console.log("UNAUTHORIZED");
            this.error = "SHIPMENT_LIST_UNAUTHORIZED";
          }
          if (x.status == 400) {
            console.log("BAD REQUEST");
            this.error = "SHIPMENT_LIST_UNKNOWNERROR";
          }
          return of(null);
        })
      )
      .subscribe((shipments) => {
        console.log("RESULT " + shipments);
        if (shipments) {
          this.list = shipments;
          this.insertCode = false;
          this.showShipment = true;
          this.bookingService.myBookingsWho = this.localStorageInfos?.email; //in realtà c'è o la mail o il numero di telefono in questo campo
          this.loaded = true;
        } else {
          this.list = [];
          this.bookingService.myBookingsWho = undefined;
          this.showShipment = false;
          this.insertCode = false;
          this.loaded = true;
        }
      });
  }

  verifyCode() {
    this.error = null;
    this.api
      .oTPValidation({ email: this.email.value, otpCode: this.code.value, sessionId: this.localStorageInfos?.sessionId })
      .pipe(
        catchError((x: HttpErrorResponse) => {
          console.log("ERROR: ", x);
          if (x.status == 401) {
            this.error = "SHIPMENT_LIST_CODENOTVALID";
            console.log("UNHAUTORIZED");
          }
          if (x.status == 400) {
            this.error = "SHIPMENT_LIST_UNKNOWNERROR";
            console.log("BAD REQUEST");
          }
          return of(null);
        }),
        switchMap((token) => {
          if (token) {
            localStorage.setItem(
              this.localStorageInfosKey,
              JSON.stringify(<OTPLocalStorageDatas>{ email: this.email.value, sessionId: this.localStorageInfos?.sessionId, token: token })
            );
            if (this.localStorageInfos) {
              this.localStorageInfos.email = this.email.value;
              this.localStorageInfos.token = token;
            }
          }
          return token == null ? of(null) : this.api.getShipments({ email: this.email.value, sessionId: this.localStorageInfos?.sessionId, token: token });
        })
      )
      .subscribe((shipments) => {
        if (shipments) {
          this.list = shipments!;
          this.insertCode = false;
          this.showShipment = true;
          this.loaded = true;
        } else {
          this.list = [];
          this.loaded = true;
          this.insertCode = false;
          this.showShipment = false;
        }
      });
  }

  details(row: OTPShipmentLMApi) {
    this.bookingService.resetBooking();
    location.href = window.location.origin + "/view/" + row.bookingCode;
  }

  logout() {
    localStorage.removeItem(this.localStorageInfosKey);
    this.list = [];
    this.insertCode = false;
    this.showShipment = false;
    this.bookingService.myBookingsWho = undefined;
    if (this.localStorageInfos) {
      this.localStorageInfos.email = null;
      this.localStorageInfos.sessionId = uuidv4();
      this.localStorageInfos.token = null;
    } else {
      this.localStorageInfos = { email: null, sessionId: uuidv4(), token: null };
    }
    this.loaded = true;
  }
}

export interface OTPLocalStorageDatas {
  email: string | null;
  token: string | null;
  sessionId: string | null;
}
