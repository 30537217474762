import { switchMap } from "rxjs";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import {
  BookingLMApiService,
  BookingShipmentLMApi,
  CustomPrivacyLMApi,
  CustomerPlantConfigurationModelExtLMApi,
  DirectionLMApi,
  GetAuthTokenFromBookingCodeResponseLMApi,
  GetInfoInputLMApi,
  GetSlotsRequestLMApi,
  GetSlotsResponseLMApi,
  NewBookingRequestLMApi,
  NewBookingResponseLMApi,
  ShipmentInfoLMApi,
  ShipmentLMApiService,
  SlotLMApi,
} from "src/app/api";
import { dateToUtcDate, getDateInTimezone, mapForPostMessageShipmentModel } from "src/app/services/utils";
import { BookingService } from "../../../services/booking.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PrivacyCompanyDialogComponent } from "src/app/dialogs/privacy-company-dialog/privacy-company-dialog.component";
import { SlotDateSelectionDialogComponent } from "src/app/dialogs/slot-date-selection-dialog/slot-date-selection-dialog.component";
import { ErrorMessageDialogComponent } from "src/app/dialogs/error-message-dialog/error-message-dialog.component";
import { ReCaptchaV3Service } from "ng-recaptcha";

@Component({
  selector: "app-book-slot",
  templateUrl: "./book-slot.component.html",
})
export class BookSlotComponent {
  loaded: boolean = false;
  configuration: CustomerPlantConfigurationModelExtLMApi | null = null;
  customPrivacy: CustomPrivacyLMApi | null = null;
  slots: SlotLMApi[] | null | undefined = null;
  firstProposedSlot: string | undefined | null = null;
  selectedSlot: SlotLMApi | null | undefined = null;
  currentDate: Date | undefined;
  nextDisabled: boolean = false;
  prevDisabled: boolean = true;
  error: string | undefined;
  recaptchaToken!: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modal: NgbModal,
    public bookingService: BookingService,
    private apiService: BookingLMApiService,
    private shipmentApiService: ShipmentLMApiService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    if (this.bookingService.fromByPlant && !this.bookingService.fromPrebooking) {
      this.bookingService.setStepperActive(3);
    } else {
      this.bookingService.setStepperActive(3);
    }
    this.bookingService.$getSlots.subscribe((getSlots) => {
      if (getSlots) {
        this.configuration = this.bookingService.warehouseConfiguration;
        this.slots = getSlots.slots;
        this.firstProposedSlot = this.slots && this.slots.length > 0 && !this.firstProposedSlot ? this.slots![0].from : this.firstProposedSlot;
        this.currentDate = getSlots.date ? getDateInTimezone(new Date(getSlots.date!), this.configuration.timezone ?? "UTC") : this.currentDate;
        this.bookingService.selectedDate = this.currentDate;
        this.prevDisabled = this.currentDate! < this.bookingService.minValidDate!;
        this.checkPrebooking(getSlots);
        this.selectedSlot = this.bookingService.selectedSlot ?? null;
        this.loaded = true;
      } else {
        this.router.navigate(["."], { relativeTo: this.route.parent });
      }
    });
    this.apiService.getCustomPrivacyByToken(this.bookingService.selectedWarehouseData?.token!).subscribe((x: CustomPrivacyLMApi) => (this.customPrivacy = x));
  }

  prevDay = () => {
    this.bookingService.selectedDate?.setDate(this.bookingService.selectedDate.getDate() - 1);
    this.bookingService.selectedDate?.setHours(0);
    this.apiCall(DirectionLMApi.Backward);
  };
  nextDay = () => {
    this.bookingService.selectedDate?.setDate(this.bookingService.selectedDate.getDate() + 1);
    this.bookingService.selectedDate?.setHours(0);
    this.apiCall(DirectionLMApi.Forward);
  };
  refresh = () => {
    this.apiCall(DirectionLMApi.Forward);
  };

  openDateDialog = () => {
    let ref = this.modal.open(SlotDateSelectionDialogComponent, { centered: true });
    ref.closed.subscribe((x) => {
      this.bookingService.selectedDate = x;
      this.apiCall(DirectionLMApi.Forward);
    });
  };

  apiCall = (direction: DirectionLMApi) => {
    this.loaded = false;
    let getSlotRequest: GetSlotsRequestLMApi = {
      dateUtc: dateToUtcDate(this.bookingService.selectedDate!).toISOString(),
      truckType: this.bookingService.data.truckType,
      minValidDate: this.bookingService.minValidDate?.toISOString(),
      products: this.bookingService.data.products,
      direction: direction,
      filteredCode: null,
      useMaxSlotLimits: true
    };
    this.apiService.getSlots(this.bookingService.selectedWarehouseData?.token!, getSlotRequest).subscribe((x: GetSlotsResponseLMApi) => {
      if (!x) {
        let ref = this.modal.open(ErrorMessageDialogComponent, { centered: true, backdrop: "static", keyboard: false });
        ref.componentInstance.message = "SLOT_ERROR_UNKNOWN";
        ref.componentInstance.date = null;
        ref.componentInstance.emailAddress = null;
        ref.componentInstance.phoneNumber = null;
      } else if (x.errorMessage) {
        if (x.errorMessage.indexOf("MAX_SLOT_LIMIT_") >= 0) {
          console.log("useMaxSlotLimits: true ERROR => " + x.errorMessage)
          this.prevDay();
        } else {
          let ref = this.modal.open(ErrorMessageDialogComponent, { centered: true, backdrop: "static", keyboard: false });
          ref.componentInstance.message = x.errorMessage;
          ref.componentInstance.date = x.date;
          ref.componentInstance.emailAddress = x.warehouseInternalContactEmail;
          ref.componentInstance.phoneNumber = x.warehouseInternalContactPhone;
        }
      } else {
        // SE è settata una prebooking min e max
        // SE è vincolante => filtro gli slot
        // SE non è vincolante => setto il parametro "outOfPrebooking" a true e quindi verrà mostrato graficamente lo slot diversamente. Sarà comunque possibile selezionarlo
        // Se non c'è prebooking prendo tutti gli slot normalmente
        this.bookingService.getSlots = this.checkPrebooking(x)!;
      }
    });
  };

  selectSlot = (slot: SlotLMApi) => {
    this.selectedSlot = slot;
    this.bookingService.selectedSlot = this.selectedSlot;
  };

  goBack = () => {
    this.router.navigate(["."], { relativeTo: this.route.parent });
  };
  goNext = (event: Event) => {
    this.error = undefined;
    (<HTMLButtonElement>event.target).disabled = true;
    if (this.customPrivacy && (this.customPrivacy.privacyIt || this.customPrivacy.cookieIt)) {
      let privacyDialogRef = this.modal.open(PrivacyCompanyDialogComponent, { centered: true, backdrop: "static", keyboard: false });
      privacyDialogRef.componentInstance.customPrivacy = this.customPrivacy;

      privacyDialogRef.closed.subscribe((x) =>
        this.recaptchaV3Service.execute('newbook_gcaptcha')
          .subscribe((token) => { this.recaptchaToken = token; this.newBooking() })
      );
      privacyDialogRef.dismissed.subscribe(_ => (<HTMLButtonElement>event.target).disabled = false);
    } else {
      const target = (<HTMLButtonElement>event.currentTarget);
      const elem: Element = document.createElement("div");
      elem.className = "spinner-border m-auto";
      const temp = target.replaceChildren(elem);
      this.recaptchaV3Service.execute('newbook_gcaptcha')
        .subscribe((token) => { this.recaptchaToken = token; this.newBooking() })
    }
  };

  private newBooking() {
    let data = this.bookingService.data;
    let bookingShipment: BookingShipmentLMApi = {
      ...data,
      carrier: data.carrier?.toUpperCase(),
      notes: data.notes?.toUpperCase(),
      referenceName: data.referenceName?.toUpperCase(),
      referenceNumber: data.referenceNumber?.toUpperCase(),
      secReferenceNumber: data.secReferenceNumber?.toUpperCase(),
      sender: data.sender?.toUpperCase(),
      receiver: data.receiver?.toUpperCase(),
      truckPlate: data.truckPlate?.toUpperCase(),
      trailerPlate: data.trailerPlate?.toUpperCase(),
      cdn: data.referenceNumber?.toUpperCase(),
      external: data.secReferenceNumber?.toUpperCase(),
      startDT: this.bookingService.selectedSlot?.from!,
      endDT: this.bookingService.selectedSlot?.to!,
      gateCode: this.selectedSlot?.code,
      filePath: this.bookingService.bookingFileTempPath,
      firstProposedSlot: this.firstProposedSlot!
    };
    let newShipmentRequest: NewBookingRequestLMApi = { shipment: bookingShipment, recaptchaResponse: this.recaptchaToken};
    this.apiService.newBooking(this.bookingService.selectedWarehouseData?.token!, newShipmentRequest).subscribe((x: NewBookingResponseLMApi) => {
      if (x && x.shipmentCodeCrypted) {
        if (window.location !== window.parent.location) {
          // The page is an iframe
          //API PER GETINFO e poi mandare POSTMESSAGE e andare su view
          this.shipmentApiService
            .getAuthTokenFromBookingCode(x.shipmentCodeCrypted)
            .pipe(
              switchMap((auth: GetAuthTokenFromBookingCodeResponseLMApi) => this.shipmentApiService.getInfo(auth.key!, { bookingCode: x.shipmentCodeCrypted } as GetInfoInputLMApi))
            )
            .subscribe((info: ShipmentInfoLMApi) => {
              if (info) {
                window.parent.postMessage(JSON.stringify(mapForPostMessageShipmentModel(info, this.bookingService.selectedWarehouseData!)), "*");
                location.href = window.location.origin + "/view/" + x.shipmentCodeCrypted + "/c";
              }
            });
        } else {
          location.href = window.location.origin + "/view/" + x.shipmentCodeCrypted + "/c";
        }
      } else if (x && x.error) {
        this.error = x.error;
      }
    });
  }

  private checkPrebooking(response: GetSlotsResponseLMApi) {
    if (this.bookingService.prebookingMinDate || this.bookingService.prebookingMaxDate) {
      if (this.bookingService.prebookingExclusive) {
        response.slots = response.slots?.filter(
          (y) =>
            (this.bookingService.prebookingMinDate
              ? getDateInTimezone(new Date(y.from!), this.bookingService.warehouseConfiguration.timezone!) >= this.bookingService.prebookingMinDate
              : false) &&
            (this.bookingService.prebookingMaxDate
              ? getDateInTimezone(new Date(y.from!), this.bookingService.warehouseConfiguration.timezone!) <= this.bookingService.prebookingMaxDate
              : false)
        );
      } else {
        response.slots
          ?.filter(
            (y) =>
              (this.bookingService.prebookingMinDate
                ? getDateInTimezone(new Date(y.from!), this.bookingService.warehouseConfiguration.timezone!) < this.bookingService.prebookingMinDate
                : false) ||
              (this.bookingService.prebookingMaxDate
                ? getDateInTimezone(new Date(y.from!), this.bookingService.warehouseConfiguration.timezone!) > this.bookingService.prebookingMaxDate
                : false)
          )
          .map((y) => {
            y.outOfPrebooking = true;
          });
      }
    }
    return response;
  }
}
