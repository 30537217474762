import { AfterViewInit, Component, ElementRef, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbDateAdapter, NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import { combineLatest, switchMap, filter, of, catchError, withLatestFrom, tap, map } from "rxjs";
import {
  ActionTypeLMApi,
  BookingLMApiService,
  BookingShipmentLMApi,
  CanSkipSlotSelectionInputLMApi,
  CheckEmailRequestLMApi,
  CodeNameOrderIconLMApi,
  ConfigurationTypesCodeNameOrderIconLMApi,
  CustomerPlantConfigurationModelExtLMApi,
  CustomerPlantModelExtLMApi,
  DeleteTempFileRequestLMApi,
  DirectionLMApi,
  EmailStatusLMApi,
  GetAuthTokenFromBookingCodeResponseLMApi,
  GetInfoInputLMApi,
  GetSlotsRequestLMApi,
  GetSlotsResponseLMApi,
  MandatoryFieldsLMApi,
  NewBookingRequestLMApi,
  NewBookingResponseLMApi,
  ProductBookingLMApi,
  ProductInfoLMApi,
  ShipmentInfoLMApi,
  ShipmentLMApiService,
  UploadTempFileResponseLMApi,
} from "src/app/api";
import { BookingService } from "src/app/services/booking.service";
import { NgbDateUtcAdapter } from "src/app/services/ngb-date-adapter";
import { addHours, fillFieldFromLocalStorage, getDateInTimezone, mapForPostMessageShipmentModel, mobileAndTabletCheck, saveInLocalStorage } from "src/app/services/utils";
import { AddProductDialogComponent } from "../../dialogs/add-product-dialog/add-product-dialog.component";
import { EditProductDialogComponent } from "../../dialogs/edit-product-dialog/edit-product-dialog.component";
import { AttachmentFileTempData } from "src/app/models/interfaces";
import * as intlTelInput from "intl-tel-input";
import { CheckEmailConfirmationComponent } from "src/app/dialogs/check-email-confirmation/check-email-confirmation.component";
import { PreBookingInformation } from "src/app/dialogs/add-prebooking-dialog/add-prebooking-dialog.component";
import { environment } from "src/environments/environment";
import { v4 as uuidv4 } from "uuid";
import { ErrorMessageDialogComponent } from "src/app/dialogs/error-message-dialog/error-message-dialog.component";
import { ByPlantService } from "src/app/services/by-plant.service";
import { TopBarService } from "../../services/topbar.service";
import { ReCaptchaV3Service } from "ng-recaptcha";
declare function downloadFileCSharp(url: string, token: string, fileName: string): any;
@Component({
  selector: "app-base-new-shipment",
  templateUrl: "./base-new-shipment.component.html",
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateUtcAdapter }],
})
export class BaseNewShipmentComponent implements AfterViewInit {
  loaded: boolean = false;
  loadedSlot: boolean = true;
  configuration: CustomerPlantConfigurationModelExtLMApi | null = null;
  mandatoryFieldsActive: MandatoryFieldsLMApi | undefined;
  typeActive: ConfigurationTypesCodeNameOrderIconLMApi | undefined;
  model!: BookingShipmentLMApi;
  selectedWarehouseData!: CustomerPlantModelExtLMApi | undefined;
  attachmentRandomGuidForTemporary: string | null | undefined;
  bookingFileTempPath: string | null | undefined = null;
  attachmentsFileList: AttachmentFileTempData[][] | null | undefined;
  attachmentsFileErrors: string[][] = [[], [], []];
  loadingAttachments: boolean[] = [false, false, false];
  minDateStruct!: NgbDateStruct;
  maxDateStruct!: NgbDateStruct;
  date: Date | undefined;
  minDate: Date | undefined;
  hour: number | undefined;
  hoursList: number[] | undefined;
  file: any;
  now: Date | null = new Date();
  intl: intlTelInput.Plugin | undefined = undefined;
  intlValid: boolean | undefined;
  emptyPhoneNumber: boolean = true;
  showAutocompleteBtn: boolean | undefined = true;
  @ViewChild("driverPhoneInput") driverPhoneInput!: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public bookingService: BookingService,
    private modal: NgbModal,
    private shipmentApiService: ShipmentLMApiService,
    public apiService: BookingLMApiService,
    private byPlantService: ByPlantService,
    topBarService: TopBarService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    if (window.location !== window.parent.location) {
      topBarService.hideNavBar();
    }

    combineLatest([this.route.paramMap, this.route.queryParamMap])
      .pipe(
        // VERIFICA SE ARRIVO DA BYPLANT
        switchMap(([paramMap, queryParamMap]) => {
          let plantId = paramMap.get("plantId");
          let lang = paramMap.get("lang");
          //GESTIONE LINGUA
          let localStorageLang = localStorage.getItem("lang");
          if (lang) this.bookingService.lang = lang;
          else if (localStorageLang) this.bookingService.lang = localStorageLang;
          let prebookingsString = queryParamMap.get("prebookings");
          if (plantId) {
            this.bookingService.fromByPlant = true;
            this.bookingService.isInjected = true;
            this.bookingService.setStepper([
              { label: "1", url: '', disabled: true },
              { label: "2", url: "book/new", desktopPhase: 0 },
              { label: "3", url: "book/new", desktopPhase: 1 },
              { label: "END", url: "book/new/slot" },
            ]);
            return of({ plantId, prebookingsString });
          }
          return of({ plantId: null, prebookingsString: null });
        }),
        switchMap(({ plantId, prebookingsString }) =>
          plantId ? this.byPlantService.verifyByPlant(plantId).pipe(map((x) => ({ plantId: x, prebookingsString }))) : of({ plantId: null, prebookingsString: null })
        ),
        // RECUPERO DATI BASE
        withLatestFrom(
          this.bookingService.$data,
          this.bookingService.$warehouseConfiguration,
          this.bookingService.$selectedWarehouseData,
          this.bookingService.$attachmentRandomGuidForTemporary,
          this.bookingService.$attachmentsFileTempList,
          this.bookingService.$lang
        )
      )
      .subscribe(([{ plantId, prebookingsString }, data, warehouseConfiguration, selectedWarehouseData, attachmentRandomGuidForTemporary, attachmentsFileList, lang]) => {
        // SE arrivo da byPlant o injected
        if (plantId) this.byPlantService.verifyPrebookings(prebookingsString, warehouseConfiguration.freezeHours);

        // SE arrivo da Prebooking byPlant con l'obbligo di inserire prebooking e il prebooking è forzato e non ho dati di prebooking salvati allora rimando al NewPrebooking
        // Serve in caso di reload della pagina
        if (
          this.bookingService.fromPrebooking &&
          this.bookingService.forcePrebooking &&
          (!this.bookingService.prebookingList || (this.bookingService.prebookingList && this.bookingService.prebookingList.length == 0))
        ) {
          this.router.navigate(["."], { relativeTo: this.route.parent?.parent });
        }
        // SE NON arrivo da un inject tipo ByPlant o ByCustomer e non ho i parametri del magazzino selezionato li rimando alla selezione della mappa
        // Serve in caso di reload della pagina
        else if (!this.bookingService.fromPrebooking && !this.bookingService.isInjected && (!warehouseConfiguration || !selectedWarehouseData)) {
          this.router.navigate(["."], { relativeTo: this.route.parent?.parent });
        }

        //SALVATAGGIO URL DI PARTENZA
        if (!sessionStorage['base_url'] || sessionStorage['base_url'] != location.href) {
          sessionStorage.setItem('base_url', location.href);
        }
        //topBarService.url$.next(sessionStorage['base_url'])

        if (this.bookingService.fromByPlant && !this.bookingService.fromPrebooking) {
          bookingService.desktopStep === 0 ? this.bookingService.setStepperActive(1) : this.bookingService.setStepperActive(2);
        } else {
          bookingService.desktopStep === 0 ? this.bookingService.setStepperActive(1) : this.bookingService.setStepperActive(2);
        }

        // Setto i dati base che arrivano dal BookingService
        const emptyProduct = {
          id: 0,
          action: undefined,
          code: null,
          quantity: undefined,
          deliveryMethod: null,
          sourceDestination: null,
        };
        this.model = data.email || this.bookingService.fromPrebooking ? data : { products: [{ ...emptyProduct }] };
        this.bookingService.data = this.model;
        if (attachmentRandomGuidForTemporary) this.attachmentRandomGuidForTemporary = attachmentRandomGuidForTemporary;
        else this.attachmentRandomGuidForTemporary = uuidv4();
        this.attachmentsFileList = attachmentsFileList;
        this.bookingFileTempPath = this.bookingService.bookingFileTempPath ?? undefined;
        this.configuration = warehouseConfiguration;
        this.bookingService.prebookingExclusive = warehouseConfiguration.cutMinMaxDates;
        this.selectedWarehouseData = selectedWarehouseData;
        if (!prebookingsString) {
          let d = new Date();
          let warehouseNowDate = getDateInTimezone(new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours()), this.configuration.timezone ?? "UTC");
          this.bookingService.minValidDate = addHours(warehouseNowDate, this.configuration.freezeHours ?? 0);
          console.log("base new shipment min: ", this.bookingService.minValidDate);
        }

        /* this.minDateStruct = {
        year: this.bookingService.minValidDate.getFullYear(),
        month: this.bookingService.minValidDate.getMonth() + 1,
        day: this.bookingService.minValidDate.getDate(),
      };
      if (this.bookingService.maxValidDate) {
        this.maxDateStruct = {
          year: this.bookingService.maxValidDate.getFullYear(),
          month: this.bookingService.maxValidDate.getMonth() + 1,
          day: this.bookingService.maxValidDate.getDate(),
        };
      } */
        this.date = this.bookingService.selectedDate;
        this.hour = this.bookingService.selectedHour;
        if (!this.date) {
          this.date = this.bookingService.minValidDate;
        }
        /* this.changeDate(); */

        /* this.getFirstDateAvailable(); */
        this.changeConfiguration();
        this.loaded = true;

        // Setto il numero di telefono se presente (Dovrebbe sempre avvenire dopo ngAfterViewInit() )
        this.model?.phoneNumber && this.intl?.setNumber(this.model?.phoneNumber);
        this.handleChangePhoneNumber();

        // Se arrivo da prebooking applico i prebooking che mi sono arrivati
        if (this.bookingService.fromPrebooking) {
          let prebookingList = this.bookingService.prebookingList;
          if (prebookingList && prebookingList.length > 0) {
            prebookingList.map((x) => this.applySinglePrebooking(x));
          }
          if (!this.model.products) {
            this.model = { ...this.model, products: [{ ...emptyProduct }] };
          }
        }
      });

    this.bookingService.preBookingInformationList.subscribe(async (preBookingInformationL) => {
      if (preBookingInformationL) {
        for (let index = 0; index < preBookingInformationL.length; index++) {
          const p = preBookingInformationL[index];
          await this.applySinglePrebooking(p);
        }
      }
    });

    // Inserito per aggiornare dati model da injection portale
    this.bookingService.$data.subscribe((data) => (this.model = data));
  }

  ngAfterViewInit() {
    // Gestione del driverPhone con INTL
    let driverPhoneInput: HTMLElement = this.driverPhoneInput.nativeElement;
    this.intl = intlTelInput(driverPhoneInput!, {
      utilsScript: "assets/intl/utils.js",
      initialCountry: "it",
      separateDialCode: true,
      preferredCountries: ["it", "gb", "es", "fr", "de", "pl", "ro", "ru"], // TODO chiedere a pasquale quali
    });

    // Setto il numero di telefono se presente (Dovrebbe sempre avvenire dopo ngAfterViewInit() )
    this.model?.phoneNumber && this.intl?.setNumber(this.model?.phoneNumber);
    this.handleChangePhoneNumber();

    // listen to "keyup", but also "change" to update when the user selects a country
    driverPhoneInput.addEventListener("change", this.handleChangePhoneNumber);
    driverPhoneInput.addEventListener("keyup", this.handleChangePhoneNumber);
    driverPhoneInput.addEventListener("countrychange", this.handleChangePhoneNumber);
  }

  handleChangePhoneNumber = () => {
    if (this.intl) {
      let number = this.intl?.getNumber();
      //IL BACKSLASH DAVANTI AL + SERVE PER LA REGEXP
      let dialCodeString = "\\+" + this.intl?.getSelectedCountryData().dialCode;
      this.emptyPhoneNumber = number ? number.replace(new RegExp(dialCodeString, "g"), "") === "" : true;
      if (number !== "") {
        this.intlValid = this.intl?.isValidNumber();
        if (this.intlValid) {
          this.model.nation = this.intl.getSelectedCountryData().iso2;
          this.model.phoneNumber = number;
        } else {
          this.model.phoneNumber = undefined;
        }
      } else {
        this.intlValid = false;
        this.model.phoneNumber = undefined;
      }
    }
  };

  // GESTIONE EMAIL INSERITA PER VERIFICARE LA VALIDITA' E BLOCKLIST
  checkEmailConfirmation() {
    if (this.model.email == null || this.model.email.trim() == "") return;
    let check: CheckEmailRequestLMApi = { email: this.model.email.trim(), plantCode: this.selectedWarehouseData?.externalCode };
    this.apiService
      .checkEmail(this.bookingService.selectedWarehouseData?.token!, check)
      .pipe(
        filter((x) => !!x),
        switchMap((x) => {
          let emailValid = x.emailStatus != EmailStatusLMApi.BlackList && x.emailStatus != EmailStatusLMApi.Existing;
          if (!emailValid && x.isInvitationOnly) {
            let ref = this.modal.open(CheckEmailConfirmationComponent, { centered: true, backdrop: "static", keyboard: false });
            ref.componentInstance.email = this.model.email;
            ref.componentInstance.plantCode = this.selectedWarehouseData?.externalCode;
            ref.componentInstance.title = "CHECK_EMAIL_INVITATION_ONLY_TITLE";
            ref.componentInstance.body = "CHECK_EMAIL_INVITATION_ONLY_BODY";
            ref.componentInstance.resend = false;
            ref.componentInstance.token = this.selectedWarehouseData?.token;

            return ref.closed;
          } else {
            if (emailValid) return of(true);

            let ref = this.modal.open(CheckEmailConfirmationComponent, { centered: true, backdrop: "static", keyboard: false });
            ref.componentInstance.email = this.model.email;
            ref.componentInstance.plantCode = this.selectedWarehouseData?.externalCode;
            ref.componentInstance.title = x.emailStatus == EmailStatusLMApi.BlackList ? "CHECK_EMAIL_BLACK_LIST_TITLE" : "CHECK_EMAIL_NOT_VERIFIED_TITLE";
            ref.componentInstance.body = x.emailStatus == EmailStatusLMApi.BlackList ? "CHECK_EMAIL_BLACK_LIST_BODY" : "CHECK_EMAIL_NOT_VERIFIED_BODY";
            ref.componentInstance.resend = x.emailStatus == EmailStatusLMApi.BlackList ? false : true;
            ref.componentInstance.token = this.selectedWarehouseData?.token;

            return ref.closed;
          }
        })
      )
      .subscribe((x) => {
        if (!x) {
          this.model.email = "";
          this.goBack();
        }
      });
  }
  /*   changeDate = () => {
    // Al cambio data annullo la selezione dell'ora.
    // ricalcolo in base alla data selezionata le ore da visualizzare
    // Se è il giorno corrente allora mostro gli slot orari compresi tra l'ora attuale e chiusura del magazzino
    // altrimenti mostro tutti gli slot da apertura a chiusura del magazzino
    if (this.date) {
      this.apiService.getWarehouseHoursListByDate(this.bookingService.selectedWarehouseData?.token!, { date: this.date.toISOString() }).subscribe((res: number[]) => {
        let hours = res.map((x) => {
          let newD = new Date(Date.UTC(this.date!.getFullYear(), this.date!.getMonth(), this.date!.getDate(), x, 0, 0, 0));
          let newH = getDateInTimezone(newD, this.configuration?.timezone ? this.configuration?.timezone : "UTC");
          return newH;
        });
        if (this.date! <= this.bookingService.minValidDate!) {
          hours = hours?.filter((x) => x >= this.bookingService.minValidDate!);
        }
        this.hoursList = hours?.map((x) => x.getHours());
        this.hour = this.hour ? this.hoursList?.find((x) => x == this.hour) : undefined;
      });
    } else {
      this.hour = undefined;
      this.hoursList = [];
    }
  }; */
  addProduct = () => {
    // In base alla dimensione dello schermo mostro la dialog, oppure aggiungo una riga alla lista
    if (!this.bookingService.isScreenSmall$.value) {
      this.changeConfiguration();
      const emptyProduct = {
        id: 0,
        action: undefined,
        code: null,
        quantity: undefined,
        deliveryMethod: null,
        sourceDestination: null,
      };
      this.model.products ? this.model.products.push(emptyProduct) : (this.model.products = [emptyProduct]);
    } else {
      let ref = this.modal.open(AddProductDialogComponent, { centered: true });
      ref.componentInstance.configuration = this.configuration;
      ref.componentInstance.typeActive = this.typeActive;
      ref.closed.subscribe((res: ProductBookingLMApi) => {
        if (res) {
          this.model.products ? this.model.products.push(res) : (this.model.products = [res]);
          this.changeConfiguration();
        }
      });
    }
  };
  editProduct = (data: ProductBookingLMApi, index: number) => {
    let ref = this.modal.open(EditProductDialogComponent, { centered: true });
    ref.componentInstance.configuration = this.configuration;
    ref.componentInstance.typeActive = this.typeActive;
    ref.componentInstance.data = data;
    ref.closed.subscribe((res: ProductBookingLMApi) => {
      if (res) {
        this.model.products ? (this.model.products[index] = res) : {};
        this.changeConfiguration();
      }
    });
  };
  deleteProduct = (content: TemplateRef<any>, index: number) => {
    of(this.bookingService.isScreenSmall$.value)
      .pipe(
        switchMap(
          (isMobile /* { */) => /*return isMobile ?  */ this.modal.open(content, { centered: true }).closed /*;  : of(true) ;*/
          /* } */
        )
      )
      .subscribe((res) => {
        if (res) {
          this.model.products ? this.model.products.splice(index, 1) : null;
          this.changeConfiguration();
          // Forzatura per resettare scelta tipo attività se rimane solo un item
          if (this.model.products?.length == 1)
            // TODO Filtro applicato per gestire tipi che sono sempre Load e Unload, vedere se possiamo migliorare
            this.typeActive!.types = this.bookingService.warehouseConfiguration.type?.types?.filter((t) =>
              this.bookingService.warehouseConfiguration.workflowsEnabled?.includes(<ActionTypeLMApi>t.code)
            );
        }
      });
  };

  changeConfiguration = () => {
    // MODIFICO LA CONFIGURAZIONE IN BASE ALLA SCELTA DEL TIPO LOAD O UNLOAD O ENTRAMBI
    let typesIncluded = this.model?.products?.filter((x) => x.action).map((x) => x.action);
    let fields: MandatoryFieldsLMApi | undefined;
    let types: CodeNameOrderIconLMApi[] | null | undefined;
    let configType = this.bookingService.warehouseConfiguration.type;
    let workflowsEnabled = this.configuration?.workflowsEnabled;
    let mandatoryFields = this.configuration?.mandatoryFields;

    if (typesIncluded && typesIncluded.length > 0) {
      if (!typesIncluded?.includes(ActionTypeLMApi.Unload)) {
        fields = mandatoryFields?.find((x) => x.workflow == ActionTypeLMApi.Load);
        types = workflowsEnabled?.includes(ActionTypeLMApi.LoadUnload) ? configType?.types : configType?.types?.filter((x) => x.code === ActionTypeLMApi.Load);
      } else if (!typesIncluded?.includes(ActionTypeLMApi.Load)) {
        fields = mandatoryFields?.find((x) => x.workflow == ActionTypeLMApi.Unload);
        types = workflowsEnabled?.includes(ActionTypeLMApi.LoadUnload) ? configType?.types : configType?.types?.filter((x) => x.code === ActionTypeLMApi.Unload);
      } else {
        fields = mandatoryFields?.find((x) => x.workflow == ActionTypeLMApi.LoadUnload);
        types = configType?.types;
      }
    } else {
      if (workflowsEnabled?.includes(ActionTypeLMApi.LoadUnload)) {
        fields = mandatoryFields?.find((x) => x.workflow == ActionTypeLMApi.LoadUnload);
        types = configType?.types;
      } else if (!workflowsEnabled?.includes(ActionTypeLMApi.Unload)) {
        fields = mandatoryFields?.find((x) => x.workflow == ActionTypeLMApi.Load);
        types = configType?.types?.filter((x) => x.code === ActionTypeLMApi.Load);
      } else if (!workflowsEnabled?.includes(ActionTypeLMApi.Load)) {
        fields = mandatoryFields?.find((x) => x.workflow == ActionTypeLMApi.Unload);
        types = configType?.types?.filter((x) => x.code === ActionTypeLMApi.Unload);
      } else {
        fields = mandatoryFields?.find((x) => x.workflow == ActionTypeLMApi.Load);
        types = configType?.types;
      }
    }

    this.mandatoryFieldsActive = fields;
    this.typeActive = { ...configType, types: types };
  };

  uploadFile = (event: any, index: number) => {
    this.attachmentsFileErrors[index].length = 0;
    let files: FileList = event.target.files;
    if (files && files.length > 0) {
      this.loadingAttachments[index] = true;
      for (let i = 0; i < files.length; i++) {
        let file: File = files[i];
        this.apiService
          .uploadTempFile(this.bookingService.selectedWarehouseData?.token!, index + 1, this.attachmentRandomGuidForTemporary!, file)
          .subscribe((r: UploadTempFileResponseLMApi) => {
            if (r && !r.errorMessage) {
              this.bookingFileTempPath = r.fileTemporaryPath;
              if (this.attachmentsFileList && this.attachmentsFileList[index])
                this.attachmentsFileList[index].push({ fileName: r.originalFileName, fileTempPath: r.fileTemporaryPath });
              else {
                this.attachmentsFileList = [[], [], []];
                this.attachmentsFileList[index] = [{ fileName: r.originalFileName, fileTempPath: r.fileTemporaryPath }];
              }
            } else if (r.errorMessage) {
              this.attachmentsFileErrors[index].push(r.errorMessage);
            }
            this.loadingAttachments[index] = false;
          });
      }
    }
    event.target.value = "";
  };

  deleteFile = (fileTempData: AttachmentFileTempData, index: number) => {
    this.attachmentsFileErrors[index].length = 0;
    let deleteFileRequest: DeleteTempFileRequestLMApi = {
      filePath: fileTempData.fileTempPath,
      originalFileName: fileTempData.fileName,
    };
    this.loadingAttachments[index] = true;
    this.apiService.deleteTempFile(this.bookingService.selectedWarehouseData?.token!, deleteFileRequest).subscribe((r: boolean) => {
      if (r) {
        if (this.attachmentsFileList && this.attachmentsFileList.length > index)
          this.attachmentsFileList[index] = this.attachmentsFileList[index].filter((x) => x.fileName !== fileTempData.fileName);
        else this.attachmentsFileList = [[], [], []];
      } else {
        this.attachmentsFileErrors[index].push("Error Deleting File");
      }
      this.loadingAttachments[index] = false;
    });
  };

  downloadFile = (fileTempData: AttachmentFileTempData) => {
    if (mobileAndTabletCheck()) {
      downloadFileCSharp(
        `${environment.baseApiUrl}/api/Booking/DownloadTempFile/${fileTempData.fileTempPath!}/${fileTempData.fileName!}`,
        this.bookingService.selectedWarehouseData?.token!,
        fileTempData.fileName!
      );
    } else {
      let downloadTempFileRequest = {
        filePath: fileTempData.fileTempPath,
        originalFileName: fileTempData.fileName,
      };
      this.apiService
        .downloadTempFile(downloadTempFileRequest.filePath!, downloadTempFileRequest.originalFileName!, this.bookingService.selectedWarehouseData?.token!)
        .subscribe((res) => {
          if (res) {
            var url = window.URL.createObjectURL(res);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display: none");
            a.href = url;
            a.download = fileTempData.fileName!;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
          }
        });
    }
  };

  setParameterOrUndefined = (model: string | undefined, json: string): string | undefined => (!model || (model && model === "") ? json : model === json ? model : undefined);
  setParameterOrMultiple = (model: string | undefined, json: string): string | undefined =>
    !model || (model && model === "") ? json : model === json ? model : model + " - " + json;

  applySinglePrebooking = async (x: PreBookingInformation) => {
    if (x) {
      let data = _.cloneDeep(x.jsonData.booking);
      if (data) {
        // SALVO LO SHIPMENT REFENCE DEL PREBOOKING NEL CAMPO REFERENCE NUMBER
        this.model.referenceNumber = this.setParameterOrMultiple(this.model.referenceNumber?.trim(), x.shipmentReference);
        if (data.trucktype) this.model.truckType = this.setParameterOrUndefined(this.model.truckType?.trim(), data.trucktype);
        if (data.email) this.model.email = this.setParameterOrMultiple(this.model.email?.trim(), data.email);
        if (data.carrier) this.model.carrier = this.setParameterOrMultiple(this.model.carrier?.trim(), data.carrier);
        if (data.truckplate) this.model.truckPlate = this.setParameterOrMultiple(this.model.truckPlate?.trim(), data.truckplate);
        if (data.trailerplate) this.model.trailerPlate = this.setParameterOrMultiple(this.model.trailerPlate?.trim(), data.trailerplate);
        if (data.sender) this.model.sender = this.setParameterOrMultiple(this.model.sender?.trim(), data.sender);
        if (data.receiver) this.model.receiver = this.setParameterOrMultiple(this.model.receiver?.trim(), data.receiver);
        if (data.note) this.model.notes = this.setParameterOrMultiple(this.model.notes?.trim(), data.note);
        if (data.external) this.model.secReferenceNumber = this.setParameterOrMultiple(this.model.secReferenceNumber?.trim(), data.external);
        if (data.phone) {
          this.model.phoneNumber = this.setParameterOrMultiple(this.model.phoneNumber?.trim(), data.phone);
          this.intl?.setNumber(this.model.phoneNumber!);
        }
        if (data.date) {
          /*
              Confronto la data della prebooking con la data minima di validità e potenziali altre prebooking caricate
              Prendo la data più grande e poi per quella data cerco le ore disponibili.
              Se c'è già un'ora segnata provo ad usarla, altrimenti prendo la prima data disponibile (generalmente l'apertura del magazzino).
            */
          let newDate = getDateInTimezone(new Date(data.date), this.configuration?.timezone ? this.configuration?.timezone : "UTC");
          let dateList: Date[] = [this.date, newDate, this.bookingService.minValidDate].filter((date) => date instanceof Date) as Date[];
          this.date =
            dateList.length > 0
              ? dateList.reduce((a: Date, b: Date): Date => {
                return a > b ? a : b;
              })
              : undefined;
          if (this.date) {
            this.apiService.getWarehouseHoursListByDate(this.bookingService.selectedWarehouseData?.token!, { date: this.date.toISOString() }).subscribe((res: number[]) => {
              let hours = res.map((x) => {
                let newD = new Date(Date.UTC(this.date!.getFullYear(), this.date!.getMonth(), this.date!.getDate(), x, 0, 0, 0));
                let newH = getDateInTimezone(newD, this.configuration?.timezone ? this.configuration?.timezone : "UTC");
                return newH;
              });
              if (this.date! <= this.bookingService.minValidDate!) {
                hours = hours?.filter((x) => x >= this.bookingService.minValidDate!);
              }
              this.hoursList = hours?.map((x) => x.getHours());
              if (this.hoursList.length > 0) this.hour = this.hour ? this.hoursList?.find((x) => x == this.hour) : this.hoursList[0];
            });
          }
        }
        if (data.mindate) {
          /* Prendo la data più grande tra minValidDate, questa nuova data e potenziali prebooking precedenti */
          let newDate = getDateInTimezone(new Date(data.mindate), this.configuration?.timezone ? this.configuration?.timezone : "UTC");
          let dateList: Date[] = [this.bookingService.prebookingMinDate, newDate, this.bookingService.minValidDate].filter(
            (date) => date instanceof Date && (this.bookingService.minValidDate ? date >= this.bookingService.minValidDate : true)
          ) as Date[];
          this.bookingService.prebookingMinDate =
            dateList.length > 0
              ? dateList.reduce((a: Date, b: Date): Date => {
                return a > b ? a : b;
              })
              : undefined;
        }
        if (data.maxdate) {
          /* Prendo la data più piccola questa nuova data e potenziali prebooking precedenti purchè siano più grandi del minValidDate*/
          let newDate = getDateInTimezone(new Date(data.maxdate), this.configuration?.timezone ? this.configuration?.timezone : "UTC");
          let dateList: Date[] = [this.bookingService.prebookingMaxDate, newDate].filter(
            (date) => date instanceof Date && (this.bookingService.minValidDate ? date >= this.bookingService.minValidDate : true)
          ) as Date[];
          this.bookingService.prebookingMaxDate =
            dateList.length > 0
              ? dateList.reduce((a: Date, b: Date): Date => {
                return a < b ? a : b;
              })
              : undefined;
        }
      }
      let products = x.jsonData.products;
      if (products && products.length > 0) {
        /*
            Per i products:
            - guardo se è presente il type nella lsita di quelli attivi. Se c'è lo aggiungo, altrimenti vuoto.
            - guardo se è presente il code nella lista, se c'è lo aggiungo altrimenti vuoto
            - guardo se è presente il deliveryMethod nella lista, se c'è lo aggiungo altrimenti vuoto
            - guardo se è presente il sourceDestination nella lista, se c'è lo aggiungo altrimenti vuoto
            - guardo se è presente la quantity, se c'è la aggiungo altrimenti vuoto
            IN CASO DI UNDEFINED VERRA' MOSTRATO IL PRODUCT, CON I CAMPI UNDEFINED COLORATI DI ROSSO
        */
        products.map((p) => {
          let type = this.typeActive?.types?.find((cp) => cp.code == p.type);
          let product: ProductBookingLMApi = {
            code: p.code ? this.configuration?.product?.types?.find((cp) => cp.code == p.code)?.code : undefined,
            action: p.type ? (type ? (type.code == ActionTypeLMApi.Load ? ActionTypeLMApi.Load : ActionTypeLMApi.Unload) : undefined) : undefined,
            deliveryMethod: p.triptype ? this.configuration?.deliveryMethod?.types?.find((cp) => cp.code == p.triptype)?.code : undefined,
            sourceDestination: p.destination ? this.configuration?.sourceDestination?.types?.find((cp) => cp.code == p.destination)?.code : undefined,
            quantity: p.value,
          };
          if (!this.typeActive?.visible && this.typeActive?.types?.length == 1) {
            product.action = this.typeActive.types[0].code == ActionTypeLMApi.Load ? ActionTypeLMApi.Load : ActionTypeLMApi.Unload;
          }
          if (!this.configuration?.product?.visible && this.configuration?.product?.types?.length == 1) {
            product.code = this.configuration.product.types[0].code;
          }
          if (!this.configuration?.deliveryMethod?.visible && this.configuration?.deliveryMethod?.types?.length == 1) {
            product.deliveryMethod = this.configuration.deliveryMethod.types[0].code;
          }
          if (!this.configuration?.sourceDestination?.visible && this.configuration?.sourceDestination?.types?.length == 1) {
            product.sourceDestination = this.configuration.sourceDestination.types[0].code;
          }
          this.model.products ? this.model.products.push(product) : (this.model.products = [product]);
          this.changeConfiguration();
        });
      }
    }
  };
  saveParameter(key: string, value: any) {
    value && value.toString().trim() != "" && saveInLocalStorage(key, value);
  }
  autocomplete = () => {
    if (this.mandatoryFieldsActive?.carrier?.visible) {
      this.model.carrier = fillFieldFromLocalStorage("carrier");
    }
    if (this.mandatoryFieldsActive?.sender?.visible) {
      this.model.sender = fillFieldFromLocalStorage("sender");
    }
    if (this.mandatoryFieldsActive?.receiver?.visible) {
      this.model.receiver = fillFieldFromLocalStorage("receiver");
    }
    if (this.mandatoryFieldsActive?.refName?.visible) {
      this.model.referenceName = fillFieldFromLocalStorage("referenceName");
    }
    if (this.mandatoryFieldsActive?.refNumber?.visible) {
      this.model.referenceNumber = fillFieldFromLocalStorage("referenceNumber");
    }
    if (this.mandatoryFieldsActive?.secRefNumber?.visible) {
      this.model.secReferenceNumber = fillFieldFromLocalStorage("secReferenceNumber");
    }
    if (this.mandatoryFieldsActive?.truckPlate?.visible) {
      this.model.truckPlate = fillFieldFromLocalStorage("truckPlate");
    }
    if (this.mandatoryFieldsActive?.trailerPlate?.visible) {
      this.model.trailerPlate = fillFieldFromLocalStorage("trailerPlate");
    }
    if (this.mandatoryFieldsActive?.note?.visible) {
      this.model.notes = fillFieldFromLocalStorage("notes");
    }
    if (this.mandatoryFieldsActive?.driverPhone?.visible) {
      let driverPhone = fillFieldFromLocalStorage("driverPhone");
      if (driverPhone) {
        this.intl?.setNumber(driverPhone);
        this.handleChangePhoneNumber();
      }
    }
  };

  checkProductData = () => {
    return !this.model.products ? true : this.model.products?.some((x) => !x.code || !x.action || !x.deliveryMethod || !x.quantity || x.quantity <= 0 || !x.sourceDestination);
  };

  goBack = () => {
    //if (this.bookingService.fromPrebooking) this.router.navigate(['newbyplantprebooking/' + this.bookingService.plantCryptedId]);
    //else
    this.router.navigate(["."], { relativeTo: this.route.parent?.parent });
  };
  goNextWithDesktopScreenMode = () => {
    this.bookingService.desktopStep = 1;
    if (this.bookingService.fromByPlant && !this.bookingService.fromPrebooking) {
      this.bookingService.setStepperActive(2);
    } else {
      this.bookingService.setStepperActive(2);
    }
  };
  goBackWithDesktopScreenMode = () => {
    if (!this.bookingService.isScreenSmall$.value) {
      this.bookingService.desktopStep = 0;
      if (this.bookingService.fromByPlant && !this.bookingService.fromPrebooking) {
        this.bookingService.setStepperActive(1);
      } else {
        this.bookingService.setStepperActive(1);
      }
    } else this.goBack();
  };
  goNext = () => {
    /* let newD = new Date(Date.UTC(this.date!.getFullYear(), this.date!.getMonth(), this.date!.getDate(), this.date!.getHours(), 0, 0, 0));
    let newH = getDateInTimezone(newD, this.configuration?.timezone ? this.configuration?.timezone : "UTC");
    let dateToFind = _.cloneDeep(this.date ? this.date : newH);
    dateToFind.setHours(+this.hour!); */

    let canSkipInput: CanSkipSlotSelectionInputLMApi = {
      productInfos: this.model.products && this.model.products.length > 0 ? this.model.products!.map(p => <ProductInfoLMApi>{
        id: p.id,
        value: p.quantity,
        code: p.code,
        destination: p.sourceDestination,
        tripType: p.deliveryMethod,
        type: p.action
      }) : [],
      truckType: this.model.truckType
    };

    let getSlotRequest: GetSlotsRequestLMApi = {
      dateUtc: this.date!.toISOString(),
      truckType: this.model.truckType,
      minValidDate: this.bookingService.minValidDate?.toISOString(),
      products: this.model.products,
      direction: DirectionLMApi.Forward,
      filteredCode: null,
      useMaxSlotLimits: true,
    };
    this.loadedSlot = false;
    let canSkip = false;

    this.apiService.canSkipSlotSelection(this.bookingService.selectedWarehouseData?.token!, canSkipInput)
      .pipe(
        tap(c => { canSkip = c }),
        switchMap(_ => this.apiService.getSlots(this.bookingService.selectedWarehouseData?.token!, getSlotRequest)),
        catchError((x) => {
          return of(null);
        })
      )
      .subscribe((x: GetSlotsResponseLMApi) => {
        if (!x) {
          let ref = this.modal.open(ErrorMessageDialogComponent, { centered: true, backdrop: "static", keyboard: false });
          ref.componentInstance.message = "SLOT_ERROR_UNKNOWN";
          ref.componentInstance.date = null;
          ref.componentInstance.emailAddress = null;
          ref.componentInstance.phoneNumber = null;

          this.loadedSlot = true;
        } else if (x.errorMessage) {
          let ref = this.modal.open(ErrorMessageDialogComponent, { centered: true, backdrop: "static", keyboard: false });
          ref.componentInstance.message = x.errorMessage;
          ref.componentInstance.date = x.date;
          ref.componentInstance.emailAddress = x.warehouseInternalContactEmail;
          ref.componentInstance.phoneNumber = x.warehouseInternalContactPhone;

          this.loadedSlot = true;
        } else {
          if (canSkip) {
            this.directBooking(x);
          } else {
            // SE è settata una prebooking min e max
            // SE è vincolante => filtro gli slot
            // SE non è vincolante => setto il parametro "outOfPrebooking" a true e quindi verrà mostrato graficamente lo slot diversamente. Sarà comunque possibile selezionarlo
            // Se non c'è prebooking prendo tutti gli slot normalmente
            if (this.bookingService.prebookingMinDate || this.bookingService.prebookingMaxDate) {
              if (this.bookingService.prebookingExclusive) {
                x.slots = x.slots?.filter((y) => {
                  let dateInTimeZone = getDateInTimezone(new Date(y.from!), this.bookingService.warehouseConfiguration.timezone!);
                  return (
                    (this.bookingService.prebookingMinDate ? dateInTimeZone >= this.bookingService.prebookingMinDate : false) &&
                    (this.bookingService.prebookingMaxDate ? dateInTimeZone <= this.bookingService.prebookingMaxDate : false)
                  );
                });
              } else {
                x.slots
                  ?.filter((y) => {
                    let dateInTimeZone = getDateInTimezone(new Date(y.from!), this.bookingService.warehouseConfiguration.timezone!);
                    return (
                      (this.bookingService.prebookingMinDate ? dateInTimeZone < this.bookingService.prebookingMinDate : false) ||
                      (this.bookingService.prebookingMaxDate ? dateInTimeZone > this.bookingService.prebookingMaxDate : false)
                    );
                  })
                  .map((y) => {
                    y.outOfPrebooking = true;
                  });
              }


            }

            //Svuoto lo slot selezionato ogni volta che torno indietro
            this.bookingService.selectedSlot = null;
            this.bookingService.selectedDate = this.date;
            this.bookingService.selectedHour = this.hour;
            this.bookingService.bookingFileTempPath = this.bookingFileTempPath;
            this.bookingService.attachmentsFileTempList = this.attachmentsFileList;
            this.bookingService.data = { ...this.model };

            this.bookingService.getSlots = x;
            this.router.navigate(["slot"], { relativeTo: this.route });
          }

        }
      });
  };

  private directBooking(slots: GetSlotsResponseLMApi) {
    this.recaptchaV3Service.execute('newbook_gcaptcha')
      .pipe(
        switchMap((token: string) => {
          let data = this.bookingService.data;
          let now = new Date();
          let futureSlots = slots.slots?.filter(s => s.from && new Date(s.from).getTime() > now.getTime());
          let automaticSlot = futureSlots && futureSlots.length > 0 ? futureSlots[0] : null;
          if (automaticSlot) {
            let bookingShipment: BookingShipmentLMApi = {
              ...data,
              carrier: data.carrier?.toUpperCase(),
              notes: data.notes?.toUpperCase(),
              referenceName: data.referenceName?.toUpperCase(),
              referenceNumber: data.referenceNumber?.toUpperCase(),
              secReferenceNumber: data.secReferenceNumber?.toUpperCase(),
              sender: data.sender?.toUpperCase(),
              receiver: data.receiver?.toUpperCase(),
              truckPlate: data.truckPlate?.toUpperCase(),
              trailerPlate: data.trailerPlate?.toUpperCase(),
              cdn: data.referenceNumber?.toUpperCase(),
              external: data.secReferenceNumber?.toUpperCase(),
              filePath: this.bookingService.bookingFileTempPath,
              startDT: automaticSlot.from!,
              endDT: automaticSlot.to!,
              gateCode: automaticSlot.code,
              firstProposedSlot: automaticSlot.from
            };
            let newShipmentRequest: NewBookingRequestLMApi = { shipment: bookingShipment, recaptchaResponse: token };
            return this.apiService.newBooking(this.bookingService.selectedWarehouseData?.token!, newShipmentRequest)
          } else
            return of(null);
        })
      )
      .subscribe((x: NewBookingResponseLMApi) => {
        if (x && x.shipmentCodeCrypted) {
          if (window.location !== window.parent.location) {
            // The page is an iframe
            //API PER GETINFO e poi mandare POSTMESSAGE e andare su view
            this.shipmentApiService
              .getAuthTokenFromBookingCode(x.shipmentCodeCrypted)
              .pipe(
                switchMap((auth: GetAuthTokenFromBookingCodeResponseLMApi) => this.shipmentApiService.getInfo(auth.key!, { bookingCode: x.shipmentCodeCrypted } as GetInfoInputLMApi))
              )
              .subscribe((info: ShipmentInfoLMApi) => {
                if (info) {
                  window.parent.postMessage(JSON.stringify(mapForPostMessageShipmentModel(info, this.bookingService.selectedWarehouseData!)), "*");
                  location.href = window.location.origin + "/view/" + x.shipmentCodeCrypted + "/c";
                }
              });
          } else {
            location.href = window.location.origin + "/view/" + x.shipmentCodeCrypted + "/c";
          }
        } else if (x && x.error) {
          //this.error = x.error;
        }
      });

  }

  redirectPrebooking = () => {
    this.apiService.redirectPrebooking(this.selectedWarehouseData?.token!).subscribe(url => location.href = url);
  }
}
