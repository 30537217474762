import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { BookingLMApiService, CustomerPlantConfigurationModelExtLMApi, DirectionLMApi, GetSlotsRequestLMApi, GetSlotsResponseLMApi, SlotLMApi } from "src/app/api";
import { dateToUtcDate, getDateInTimezone } from "src/app/services/utils";
import { BookingService } from "../../../services/booking.service";
import { SlotDateSelectionDialogComponent } from "src/app/dialogs/slot-date-selection-dialog/slot-date-selection-dialog.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-edit-slot",
  templateUrl: "./edit-slot.component.html",
})
export class EditSlotComponent {
  loaded: boolean = false;
  configuration: CustomerPlantConfigurationModelExtLMApi | null = null;
  slots: SlotLMApi[] | null | undefined = null;
  initialSlot!: SlotLMApi;
  selectedSlot: SlotLMApi | null | undefined = null;
  currentDate: Date | undefined;
  nextDisabled: boolean = false;
  prevDisabled: boolean = true;
  error: string | undefined;

  constructor(private router: Router, private route: ActivatedRoute, private modal: NgbModal, public bookingService: BookingService, private apiService: BookingLMApiService) {
    if (!this.bookingService.data || !this.bookingService.editInitialBookingDate) {
      this.router.navigate(["."], { relativeTo: this.route.parent?.parent });
    }

    this.bookingService.setStepperActive(3);
    this.configuration = this.bookingService.warehouseConfiguration;

    this.bookingService.$getSlots.subscribe((getSlots) => {
      if (getSlots) {
        this.slots = getSlots.slots;
        this.currentDate = getSlots.date ? getDateInTimezone(new Date(getSlots.date!), this.configuration!.timezone ?? "UTC") : this.currentDate;
        this.bookingService.selectedDate = this.currentDate;
        this.prevDisabled = this.currentDate! < this.bookingService.minValidDate!;

        this.initialSlot = {
          code: this.bookingService.data.gateCode,
          from: this.bookingService.editInitialBookingDate,
          maxDelay: 25,
        };
        this.selectedSlot = this.bookingService.selectedSlot ?? bookingService.checkSameSlotValid ? this.initialSlot : null;
        this.loaded = true;
      } else {
        this.slots = null;
        this.initialSlot = {
          code: this.bookingService.data.gateCode,
          from: this.bookingService.editInitialBookingDate,
          maxDelay: 25,
        };
        if (bookingService.checkSameSlotValid) {
          this.selectedSlot = this.initialSlot;
        }
        this.loaded = true;
      }
    });
  }

  prevDay = () => {
    this.currentDate?.setDate(this.currentDate.getDate() - 1);
    this.currentDate?.setHours(0);
    this.apiCall(DirectionLMApi.Backward);
  };
  nextDay = () => {
    this.currentDate?.setDate(this.currentDate.getDate() + 1);
    this.currentDate?.setHours(0);
    this.apiCall(DirectionLMApi.Forward);
  };
  refresh = () => {
    this.apiCall(DirectionLMApi.Forward);
  };

  openDateDialog = () => {
    let ref = this.modal.open(SlotDateSelectionDialogComponent, { centered: true });
    ref.closed.subscribe((x) => {
      this.bookingService.selectedDate = x;
      this.apiCall(DirectionLMApi.Forward);
    });
  };

  apiCall = (direction: DirectionLMApi) => {
    this.loaded = false;
    let getSlotRequest: GetSlotsRequestLMApi = {
      dateUtc: dateToUtcDate(this.bookingService.selectedDate!).toISOString(),
      truckType: this.bookingService.data.truckType,
      minValidDate: this.bookingService.minValidDate?.toISOString(),
      products: this.bookingService.data.products,
      direction: direction,
      filteredCode: this.bookingService.data.code,
      useMaxSlotLimits: true
    };
    this.apiService.getSlots(this.bookingService.selectedWarehouseData?.token!, getSlotRequest).subscribe((x: GetSlotsResponseLMApi) => {
      this.bookingService.getSlots = x;
    });
  };

  selectSlot = (slot: SlotLMApi) => {
    this.selectedSlot = slot;
    this.bookingService.selectedSlot = this.selectedSlot;
  };

  goBack = () => {
    this.router.navigate(["."], { relativeTo: this.route.parent?.parent });
  };
  goNext = () => {
    this.error = undefined;
    this.bookingService.selectedSlot = this.selectedSlot;
    this.router.navigate(["add-reason"], { relativeTo: this.route.parent });
  };
}
