import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-img-base64',
  template: `<img height="30" [ngClass]="width30px ? 'w-30px' : ''" [src]="imagePath" />`,
})
export class ImgBase64Component implements OnInit {
  @Input() base64string: string = '';
  @Input() width30px: boolean = false;
  imagePath: any;
  constructor(private _sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.base64string);
  }
}
